import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { VARIABLES } from "../../../Constants";
import secureLocalStorage from "react-secure-storage";
import AddTechnicalQuestion from "./AddTechnicalQuestion";
import Snackbar from "../../../Base/Snackbar";
import { SnackbarContext } from "../../../Context/SnackbarContext";
import { InterviewQuestionContext } from "../../../Context/InterviewQuestionMasterContext";
import { WorkflowContext } from "../../../Context/WorkflowContext";
import { SequenceContext } from "../../WorkflowStepperForm/SequenceContextProvider";
import AddStepperTab from "../AddStepperTab/AddStepperTab";
import { useLocation } from "react-router-dom";
import { TypemasterDropdownContext } from "../../../Context/TypemasterDropdownContext";

const TechnicalInterviewRound = ({ formId }) => {
  const {
    technicalInterviewQuestion,
    getTechnicalInterviewQuestions,
    isQuestionLoading,
    getTechnicalInterviewDetails,
    technicalDetails,
  } = useContext(InterviewQuestionContext);
  const TechnicalData = technicalDetails;

  console.log("technicalInterviewQuestion: ", technicalInterviewQuestion); //all interview questionnaire data
  console.log("prepopulated Interview details: ", TechnicalData); //all interview questionnaire data

  const { interviewActionDropdownData } = useContext(TypemasterDropdownContext);

  const { workflowSession, getWorkflowSession } = useContext(WorkflowContext);
  const {
    level,
    sequenceList,
    nextLevel,
    prevLevel,
    submitLoading,
    setSubmitLoading,
  } = useContext(SequenceContext);

  const { setSubmitMessage } = useContext(SnackbarContext); //message after submitting data

  const [newQueSubmitLoading, setNewQueSubmitLoading] = useState(false);

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);

  const privileges = secureLocalStorage.getItem("privileges");

  const location = useLocation();

  const candidate_id = location.state?.candidate_id;
  const requisition_id = location.state?.requisition_id;
  const job_opening_id = location.state?.job_opening_id;

  const interviewerData =
    TechnicalData?.interview_details?.interview_feedback?.find(
      (info) => info.interviewer.id === userID
    );
  console.log("interviewerData ", interviewerData);

  const [isEditable, setIsEditable] = useState(
    interviewerData && interviewerData?.questions_data?.length === 0
  );

  const isDefaultDisabled =
    interviewerData && interviewerData?.questions_data?.length !== 0;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
    control,
    reset,
    setValue,
  } = useForm();

  const toggleEdit = () => {
    setIsEditable(!isEditable);
  };

  console.log(isDirty);

  const handleFormSubmit = (data) => {
    if (isDirty) {
      handleSubmit(onSubmitScores)(data);
    } else {
      nextLevel();
    }
  };

  const isFormFilled = workflowSession.find((api) => api.tab_id == formId?.id);

  // Extract all categories from the Technical questions and store them into a single array
  const allCategories = technicalInterviewQuestion?.flatMap(
    (question) => question.category
  );
  console.log("allCategories: ", allCategories);

  //creating new set to remove duplicate values
  const uniqueCategories = [...new Set(allCategories)];
  console.log("uniqueCategories: ", uniqueCategories);

  const [manualMarks, setManualMarks] = useState(
    Array(uniqueCategories.length).fill("") //Create an array with a length equal to the number of unique categories and store with empty string
  );
  console.log(manualMarks);

  const [totalManualMarks, setTotalManualMarks] = useState(0); //stores the total of manual marks
  console.log(totalManualMarks);
  // Function to update the toggle status for a question
  const [toggleStatus, setToggleStatus] = useState({});

  const updateToggleStatus = (index, status) => {
    setToggleStatus((prevState) => ({
      ...prevState, //spreads previous state
      [index]: status, //update status with specified index
    }));
  };

  // State variable to hold row data
  const [rowData, setRowData] = useState([]);
  console.log("rowData:", rowData);
  // Update the row data array with the new score
  const handleScoreChange = (index, score, comments, info) => {
    console.log(index);
    console.log(score);
    console.log(comments);
    console.log(info);
    const updatedRowData = [...rowData]; //create a copy of rowData
    // if(info.question == rowData.map(api=>api.question))
    updatedRowData[index] = {
      ...updatedRowData[index], //existing data
      score: score, //update score
      comments: comments, // update comments
      info: info, //update info
    };
    setRowData(updatedRowData); //update state with all above data
  };

  // State variable to store categoryStats
  const [categoryStats, setCategoryStats] = useState({});
  console.log("categoryStats : ", categoryStats);

  // To calculate average marks
  const calculateAverageMarks = (category) => {
    if (categoryStats[category]) {
      // checks if that category presents in categoryStats
      const { count, totalScore } = categoryStats[category]; //takes count and totalscore from categorystats
      console.log("count", count);
      console.log("totalScore", totalScore);
      const overallScore = count * 5; //Assuming each question has max 5 marks
      console.log("overallScore", overallScore);
      return totalScore / overallScore;
    }
    return 0; // if that category doesn't present in categoryStats returns 0
  };

  const handleManualMarksChange = (index, value) => {
    console.log("first");
    const updatedManualMarks = [...manualMarks]; //create a copy of manual marks
    updatedManualMarks[index] = parseInt(value); //convert the value to integer
    setManualMarks(updatedManualMarks); //update the array of manual marks
    const total = updatedManualMarks.reduce((acc, mark) => acc + mark, 0); //Calculating total manual marks by adding all marks in the updated array
    setTotalManualMarks(total); //updated the state with total marks
  };

  //form submission
  const onSubmitScores = (data) => {
    console.log(data);
    console.log("totalManualMarks: ", totalManualMarks);
    console.log("rowData: ", rowData);
    // const question_data = rowData.filter((api) => api !== undefined || api.question);
    const question_data = rowData.filter(
      (obj) =>
        typeof obj !== "undefined" &&
        obj.hasOwnProperty("info") &&
        obj.info.hasOwnProperty("question")
    );
    console.log(question_data);
    var user = JSON.stringify({
      candidate_interview_id: TechnicalData?.interview_details?.id,
      candidate_job_id: candidate_id,
      requisition_id: requisition_id,
      questions: question_data?.map((info) => ({
        question: info?.info?.question,
        score: info?.score,
        comments: info?.comments,
      })),
      categories: uniqueCategories.map((category, index) => ({
        category: category,
        score: manualMarks[index] || 0,
      })),
      interviewer_id: userID,
      comments: data.comment,
      candidate_accept_reject:
        data?.is_selected === "Draft"
          ? 0
          : data?.is_selected === "Accept"
            ? 1
            : data?.is_selected === "Reject"
              ? 2
              : null,
      total_score: totalManualMarks,
      created_by_id: userID,
      // updated_by_id: userID,
      workflow_session: {
        tab_id: formId?.id,
        workflow_id: formId?.WF,
        user_id: userID,
      },
    });

    console.log(user);

    var config = {
      method: "post",
      url: VARIABLES.url + `/api/technicalinterview/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(JSON.stringify(data));
    setSubmitLoading(true);
    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data) {
          setSubmitLoading(false);
          setSubmitMessage({
            type: "success",
            icon: "check",
            message: "Interview completed successfully",
          });
          getWorkflowSession(candidate_id, requisition_id);
          nextLevel();
          reset();
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: error?.response?.data?.message || "Interview failed",
        });
      });
  };

  useEffect(() => {
    getTechnicalInterviewQuestions(job_opening_id);
  }, []);

  useEffect(() => {
    getTechnicalInterviewDetails(candidate_id, requisition_id);
  }, []);

  useEffect(() => {
    //object to store the count of questions and total scores for each category
    const newCategoryStats = {};
    console.log(newCategoryStats);

    // Iterate over each row in the `rowData`
    rowData?.forEach((row) => {
      // Extract the category and score from the row
      const category = row?.info?.category[0];
      const score = parseInt(row?.score);

      // If the category already exists in `newCategoryStats`
      // update count and total score
      if (newCategoryStats[category]) {
        newCategoryStats[category].count++;
        newCategoryStats[category].totalScore += score;
      } else {
        newCategoryStats[category] = { count: 1, totalScore: score }; //if not then , initialize it with count 1 and the current score
      }
    });
    setCategoryStats(newCategoryStats); //update the state with new stats
  }, [rowData]);

  useEffect(() => {
    if (manualMarks.length !== 0) {
      const total = manualMarks.reduce((acc, mark) => acc + mark, 0);
      setTotalManualMarks(total);
    }
  }, [manualMarks]);

  useEffect(() => {
    var defaultValues = {};
    if (interviewerData?.categories_data) {
      const category_data = interviewerData?.categories_data?.map(
        (api) => api.score
      );
      setManualMarks(category_data);
    }
    if (interviewerData?.questions_data) {
      const question_data = interviewerData?.questions_data?.map((api) => {
        return { score: api.score, comments: api.comments };
      });
      setRowData(question_data);
    }
    if (interviewerData) {
      defaultValues.comment = interviewerData?.comments;
      defaultValues.is_selected =
        interviewerData?.candidate_accept_reject === 0
          ? "Draft"
          : interviewerData?.candidate_accept_reject === 1
            ? "Accept"
            : interviewerData?.candidate_accept_reject === 2
              ? "Reject"
              : null;
      setTotalManualMarks(interviewerData?.total_score);
    }
    reset(defaultValues);
  }, [interviewerData]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h4 className="nk-block-title">Technical Interview Round</h4>
            {isQuestionLoading || newQueSubmitLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          {privileges.includes("rc_update_provision") && (
            <div className="nk-block-head-content pe-2">
              {interviewerData && interviewerData?.length !== 0 && (
                <>
                  <div className="col-12 d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-lg btn-primary"
                      onClick={toggleEdit}
                    >
                      {isEditable ? "Cancel" : "Edit"}
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
          {/* checks that is_iterable is true or not 
          if yes then it shows the button/form to create an alternate tab */}
          {formId?.is_iterable && formId?.is_iterable == true && (
            <AddStepperTab formId={formId} />
          )}
        </div>
      </div>
      {isQuestionLoading ? (
        <div></div>
      ) : (
        <div className="col-lg-12 col-md-12 col-sm-12">
          <>
            {TechnicalData?.interview_details ? (
              <>
                {technicalInterviewQuestion &&
                  technicalInterviewQuestion.length !== 0 ? (
                  <div className="card">
                    <div className="card-inner">
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Category name</th>
                              <th>Average of Questions</th>
                              <th>
                                Manual Marks
                                <span className="text-danger"> *</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {uniqueCategories?.map((category, index) => (
                              <tr key={index}>
                                <td>{category}</td>
                                <td>
                                  {calculateAverageMarks(category)
                                    ? calculateAverageMarks(category)
                                    : 0}
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id={`number-forms-manualmarks[${index}]`}
                                    {...register(`manualmarks.${index}`, {
                                      required: true,
                                    })}
                                    value={manualMarks[index]}
                                    onWheel={(e) => e.target.blur()}
                                    onChange={(e) =>
                                      handleManualMarksChange(
                                        index,
                                        e.target.value
                                      )
                                    }
                                    disabled={
                                      (interviewerData &&
                                        interviewerData?.categories_data
                                          ?.length !== 0 &&
                                        !isEditable) ||
                                      !calculateAverageMarks(category)
                                    }
                                  />
                                  {errors.manualmarks &&
                                    errors.manualmarks[index] && (
                                      <span className="fs-8 text-danger">
                                        Manual marks are required
                                      </span>
                                    )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="row ps-5">
                      {/* <div className="d-flex justify-between"> */}
                      <div className="col-6">
                        <button
                          id="button-button-addtechquestion"
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#addtechquestion"
                          aria-label="Close"
                        >
                          Add question
                        </button>
                      </div>
                      <div className="col-6 d-flex justify-end">
                        <label className="w-25 p-2 fw-bold">
                          Total Marks :
                        </label>
                        <div className="form-control w-25 me-4">
                          {totalManualMarks}
                        </div>
                      </div>
                      {/* </div> */}
                    </div>

                    <form
                      onSubmit={handleSubmit(handleFormSubmit)}
                      autoComplete="off"
                    >
                      <div className="card-inner">
                        <div className="table-responsive">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th>Question</th>
                                <th>Expected Answer</th>
                                <th>Category</th>
                                <th>Status</th>
                                <th>
                                  <span>Score</span>
                                  <br />
                                  <span>(Out of 5)</span>
                                </th>
                                <th>Comments</th>
                              </tr>
                            </thead>
                            <tbody>
                              {technicalInterviewQuestion?.map(
                                (info, index) => (
                                  <tr key={index}>
                                    {console.log(index)}
                                    <td>{info.question}</td>
                                    <td>{info.answer}</td>
                                    <td>{info.category?.[0]}</td>
                                    <td>
                                      <div className="nk-tb-col">
                                        <div className="custom-control custom-switch">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id={`customSwitches${index}`}
                                            checked={
                                              toggleStatus[index] || false
                                            }
                                            onChange={(
                                              e //updates toggle
                                            ) =>
                                              updateToggleStatus(
                                                index,
                                                e.target.checked
                                              )
                                            }
                                          />
                                          <label
                                            className="custom-control-label"
                                            id="button-button-status"
                                            htmlFor={`customSwitches${index}`}
                                          ></label>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        id={`number-forms-score[${index}]`}
                                        className="form-control"
                                        {...register(`scores.${index}`, {
                                          min: { value: 0, message: "Score must be at least 0" }, // Minimum score validation
                                          max: { value: 5, message: "Score cannot exceed 5" }, // Maximum score validation
                                        })}
                                        value={rowData[index]?.score || ""}
                                        onWheel={(e) => e.target.blur()}
                                        onChange={(e) =>
                                          handleScoreChange(
                                            index,
                                            e.target.value,
                                            rowData[index]?.comments || "", // Previous comments for the row or empty string
                                            info // info of row
                                          )
                                        }
                                        disabled={
                                          !toggleStatus[index] ||
                                          (isDefaultDisabled && // Disables input field if toggleStatus for the current index is false
                                            !isEditable)
                                        }
                                      />
                                      {errors.scores && errors.scores[index] && (
                                        <span className="fs-8 text-danger">
                                          {errors.scores[index].message}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      <textarea
                                        className="form-control"
                                        id={`textarea-forms-comments[${index}]`}
                                        {...register(`comments[${index}]`)}
                                        value={rowData[index]?.comments || ""}
                                        onChange={(e) =>
                                          handleScoreChange(
                                            index,
                                            rowData[index]?.score || "", // Previous score for the row or empty string
                                            e.target.value,
                                            info // info of row
                                          )
                                        }
                                        disabled={
                                          !toggleStatus[index] ||
                                          (isDefaultDisabled && // Disables input field if toggleStatus for the current index is false
                                            !isEditable)
                                        }
                                      />
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="col-lg-6 mt-4 mb-4">
                          <div className="form-group">
                            <label className="form-label">Comments</label>
                            <textarea
                              className="form-control"
                              id="text-forms-comment"
                              placeholder="Enter Comment"
                              {...register("comment")}
                              disabled={isDefaultDisabled && !isEditable}
                            />
                          </div>
                        </div>
                        <div className="col-6"></div>
                        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                          <ul className="row g-3">
                            {interviewActionDropdownData.map(
                              (action, index) => (
                                <li className="col-sm-4" key={index}>
                                  <div className="custom-control custom-radio me-3">
                                    <input
                                      type="radio"
                                      id={`checkbox-form-is-selected-hrreview-${action.value.toLowerCase()}`}
                                      className="custom-control-input"
                                      value={action.value}
                                      {...register("is_selected", {
                                        required: true,
                                      })}
                                      disabled={isFormFilled}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for={`checkbox-form-is-selected-hrreview-${action.value.toLowerCase()}`}
                                    >
                                      {action.name}
                                    </label>
                                  </div>
                                </li>
                              )
                            )}
                          </ul>
                          {errors.is_selected && (
                            <span className="fs-8 text-danger">
                              Please select any one
                            </span>
                          )}
                        </div>

                        <div className="col-12 d-flex mt-4">
                          <div className="form-group">
                            {level !== 0 && (
                              <button
                                type="button"
                                className="btn btn-lg btn-primary me-3"
                                id="button-button-prevlevel"
                                onClick={() => prevLevel()}
                              >
                                Prev
                              </button>
                            )}
                          </div>
                          <div className="form-group">
                            {submitLoading ? (
                              <button className="btn text-dark" disabled>
                                Loading...
                              </button>
                            ) : (
                              <>
                                {sequenceList.length - 2 > level ? (
                                  TechnicalData?.interview_details
                                    ?.interview_action ? (
                                    <button
                                      type="submit"
                                      className="btn btn-lg btn-primary"
                                      id="submit-button-techinterview"
                                    // onClick={() => nextLevel()}
                                    >
                                      Next
                                    </button>
                                  ) : (
                                    <button
                                      type="submit"
                                      className="btn btn-lg btn-primary"
                                      id="submit-button-techinterview"
                                    >
                                      Next
                                    </button>
                                  )
                                ) : TechnicalData?.interview_details
                                  ?.interview_action ? (
                                  <button
                                    type="submit"
                                    className="btn btn-lg btn-primary"
                                    id="submit-button-techinterview"
                                  // onClick={() => nextLevel()}
                                  >
                                    Submit
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    className="btn btn-lg btn-primary"
                                    id="submit-button-techinterview"
                                  >
                                    Submit
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                ) : (
                  <div>No Questions found for Technical interview round.</div>
                )}
              </>
            ) : (
              "Technical Interview is not scheduled for this candidate."
            )}
          </>
        </div>
      )}

      <Snackbar />
      <AddTechnicalQuestion setNewQueSubmitLoading={setNewQueSubmitLoading} />
    </>
  );
};

export default TechnicalInterviewRound;
