import React, { useContext, useState } from "react";
import { NotificationsContext } from "../../Context/NotificationsContextProvider";
import ReactPaginate from "react-paginate";
import moment from "moment";
import WelcomeMessage from "../../Base/WelcomeMessage";
import Snackbar from "../../Base/Snackbar";
import secureLocalStorage from "react-secure-storage";
import { NavLink, useNavigate } from "react-router-dom";

function Notifications() {
  const {
    allNotifications,
    noPages,
    currentPage,
    entries,
    totalEntries,
    setEntries,
    getAllNotifications,
    updateNotificationStatus,
    readNotifications,
    unReadNotifications,
    setReadNotifications,
    setUnReadNotifications,
    setAllNotifications,
    areNotificationsLoading,
    noNotificationsData,
  } = useContext(NotificationsContext);

  const privileges = secureLocalStorage.getItem("privileges");

  const [searchRequest, setSearchRequest] = useState("");

  const navigate = useNavigate();

  const handleNotificationStatus = (id, type) => {
    setAllNotifications(
      allNotifications.map((notification) => {
        if (notification.id === id) {
          return {
            ...notification,
            is_read: !notification.is_read,
          };
        }
        return notification;
      })
    );
    if (type === "read") {
      setReadNotifications(readNotifications.filter((e) => e.id !== id));
      setUnReadNotifications([...unReadNotifications, id]);
    } else {
      setUnReadNotifications(unReadNotifications.filter((e) => e.id !== id));
      setReadNotifications([...readNotifications, id]);
    }
  };
  const onChangeHandler = (event) => {
    setSearchRequest(event.target.value);
  };
  const onSearchHandler = (method, event) => {
    event.preventDefault();
    // if (method === "search") {
    //   getTamData(1, searchRequest);
    // } else {
    //   getTamData(1);
    //   setSearchRequest("");
    // }
  };
  return (
    <>
      <WelcomeMessage />
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">Notifications</h3>
            {areNotificationsLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div class="nk-block-head-content">
            <a
              className="btn btn-light d-none d-sm-inline-flex"
              id="button-back"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              id="button-back"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            {/* To-Do: Impliment Search for notifications here */}
            {/* <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchRequest}
                      className="form-control"
                      placeholder="Search by title..."
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        Clear Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div> */}
            <div className="nk-block-head nk-block-head-sm">
              <div className="nk-block-between">
                {/* To-Do: Add privilege */}
                {/* {privileges.includes("sync_notifications") ? ( */}
                <button
                  className="btn btn-sm btn-outline-primary ms-auto me-4 mt-3"
                  onClick={updateNotificationStatus}
                >
                  Update
                </button>
                {/* ) : (
                  ""
                )} */}
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>#</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Title</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Message</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Date</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Action</span>
                      </div>
                    </div>
                    {allNotifications.length !== 0 ? (
                      allNotifications.map((notification, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                {index + 1 + entries * (currentPage - 1)}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                <NavLink
                                  to={notification.redirection_page_path}
                                >
                                  {notification.title}
                                </NavLink>
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {notification.message}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {moment(notification?.created_at).format(
                                "Do MMMM YYYY, h:mm:ss a"
                              )}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              <span
                                className={`badge text-bg-${!notification.is_read ? "warning" : "success"
                                  }`}
                                style={{ minWidth: "105px", cursor: "pointer" }}
                                onClick={() =>
                                  handleNotificationStatus(
                                    notification.id,
                                    notification.is_read ? "read" : "unread"
                                  )
                                }
                              >
                                {notification.is_read
                                  ? "mark as unread"
                                  : "mark as read"}
                              </span>
                            </span>
                          </div>
                        </div>
                      ))
                    ) : noNotificationsData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Good job! You are all caught up.</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={3}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={noPages}
                    initialPage={currentPage - 1}
                    marginPagesDisplayed={2}
                    disableInitialCallback={true}
                    onPageChange={(selectedPage) => {
                      setReadNotifications([]);
                      setUnReadNotifications([]);
                      getAllNotifications(selectedPage.selected + 1, entries);
                    }}
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"paginate_button page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"paginate_button page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {totalEntries === null ? 0 : totalEntries}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={entries}
                        onChange={(e) => {
                          setEntries(e.target.value);
                          getAllNotifications(currentPage, e.target.value);
                        }}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar />
    </>
  );
}

export default Notifications;
