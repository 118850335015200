// export const Formdata = {
//   "tabs": [
//     {
//       "id": 1,
//       "name": "Tab 1",
//       "parent_id": 0,
//       "sequence": 1,
//       "depends_on": [0],
//       "form": null,
//       "WF": 1,
//       "tabs": [
//         {
//           "id": 2,
//           "name": "Tab 1.1",
//           "parent_id": 1,
//           "sequence": 1,
//           "depends_on": [0],
//           "form": {
//             "id" : 1,
//             "form_path" : "Stepper/AssignAssignment/AssignAssignment",
//             "form_name" : "Assign Assignment"
//           },
//           "WF": 1,
//           "tabs": [],
//         },
//         {
//           "id": 3,
//           "name": "Tab 1.2",
//           "parent_id": 1,
//           "sequence": 2,
//           "depends_on": [2],
//           "form": {
//             "id" : 2,
//             "form_path" : "Stepper/AssignmentReview/AssignmentReview",
//             "form_name" : "Assignment Review"
//           },
//           "WF": 1,
//         "tabs": [],
//         },
//       ]
//     },
//     {
//       "id": 4,
//       "name": "Tab 2",
//       "parent_id": 0,
//       "sequence": 2,
//       "depends_on": [2,3],
//       "form": {
//         "id" : 3,
//         "form_path" : "Stepper/MettleTest/MettleTest",
//         "form_name" : "Test"
//       },
//       "WF": 1,
//         "tabs": [],
//     },
//     {
//       "id": 5,
//       "name": "Tab 3",
//       "parent_id": 0,
//       "sequence": 3,
//       "depends_on": [0],
//       "form": null,
//       "WF": 1,
//       "tabs": [
//         {
//           "id": 6,
//           "name": "Tab 3.1",
//           "parent_id": 5,
//           "sequence": 1,
//           "depends_on": [0],
//           "form": {
//             "id" : 4,
//             "form_path" : "Stepper/MettleTestReview/MettleTestReview",
//             "form_name" : "Test Review"
//           },
//           "WF": 1,
//           "tabs": [],
//         },
//         {
//           "id": 7,
//           "name": "Tab 3.2",
//           "parent_id": 5,
//           "sequence": 2,
//           "depends_on": [2,6],
//           "form": null,
//           "WF": 1,
//           "tabs": [
//             {
//               "id": 8,
//               "name": "Tab 3.2.1",
//               "parent_id": 7,
//               "sequence": 1,
//               "depends_on": [7],
//               "form": {
//                 "id" : 1,
//                 "form_path" : "Stepper/SendOffer/SendOffer",
//                 "form_name" : "Send Offer"
//               },
//               "WF": 1,
//                "tabs": [],
//             }
//           ]
//         }
//       ]
//     }
//   ],
//   "sequence": [[0, 0, 0], [0, 1], [1, 0], [2, 0], [2, 1, 0], [3]]
// };

export const candidateFormWorkflow = {
  "candidate_job_id": 1350,
  "total_progress": 29,
  "tabs": [
    {
      "id": 16535,
      "name": "Personal Detailsss",
      "parent_id": 0,
      "sequence": 1,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": {
        "id": 17,
        "form_name": "Personal Details Retrieve",
        "form_path": "Stepper/CandidateDetails/PersonalDetails"
      },
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [],
      "level": 0
    },
    {
      "id": 16358,
      "name": "Candidate Details",
      "parent_id": 0,
      "sequence": 1,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": null,
      "WF": 67,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [
        {
          "id": 16359,
          "name": "Personal Details",
          "parent_id": 16358,
          "sequence": 2,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": {
            "id": 17,
            "form_name": "Personal Details Retrieve",
            "form_path": "Stepper/CandidateDetails/PersonalDetails"
          },
          "WF": 67,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        },
        {
          "id": 16360,
          "name": "Educational Details",
          "parent_id": 16358,
          "sequence": 3,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": {
            "id": 18,
            "form_name": "Educational Details Retrieve",
            "form_path": "Stepper/CandidateDetails/EducationalDetails"
          },
          "WF": 67,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        },
        {
          "id": 16361,
          "name": "Professional Details",
          "parent_id": 16358,
          "sequence": 4,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": {
            "id": 19,
            "form_name": "Professional Details Retrieve",
            "form_path": "Stepper/CandidateDetails/ProfessionalDetails"
          },
          "WF": 67,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        },
        {
          "id": 16362,
          "name": "Additional Details",
          "parent_id": 16358,
          "sequence": 5,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": {
            "id": 20,
            "form_name": "Additional Details Retrieve",
            "form_path": "Stepper/CandidateDetails/AdditionalDetails"
          },
          "WF": 67,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        }
      ],
      "level": 0
    },
    {
      "id": 16536,
      "name": "Educational Details",
      "parent_id": 0,
      "sequence": 2,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": {
        "id": 18,
        "form_name": "Educational Details Retrieve",
        "form_path": "Stepper/CandidateDetails/EducationalDetails"
      },
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [],
      "level": 0
    },
    {
      "id": 16537,
      "name": "Professional Details",
      "parent_id": 0,
      "sequence": 3,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": {
        "id": 19,
        "form_name": "Professional Details Retrieve",
        "form_path": "Stepper/CandidateDetails/ProfessionalDetails"
      },
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [],
      "level": 0
    },
    {
      "id": 16538,
      "name": "Additional Details",
      "parent_id": 0,
      "sequence": 4,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": {
        "id": 20,
        "form_name": "Additional Details Retrieve",
        "form_path": "Stepper/CandidateDetails/AdditionalDetails"
      },
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [],
      "level": 0
    },
    {
      "id": 16539,
      "name": "Telephonic Round",
      "parent_id": 0,
      "sequence": 5,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": null,
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [
        {
          "id": 16540,
          "name": "Telephonic Interview",
          "parent_id": 16539,
          "sequence": 6,
          "depends_on": [
            0
          ],
          "stage": 1,
          "status": "Scheduled",
          "form": {
            "id": 13,
            "form_name": "Telephonic Interview",
            "form_path": "Stepper/TelephonicInterviewRound/TelephonicRound"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        },
        {
          "id": 16541,
          "name": "Telephonic Interview Review",
          "parent_id": 16539,
          "sequence": 7,
          "depends_on": [
            16540
          ],
          "stage": 1,
          "status": "Scheduled",
          "form": {
            "id": 24,
            "form_name": "Telephonic Interview Review",
            "form_path": "Stepper/TelephonicInterviewRound/TelephonicInterviewReview"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        }
      ],
      "level": 0
    },
    {
      "id": 16363,
      "name": "Interview Details",
      "parent_id": 0,
      "sequence": 6,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": null,
      "WF": 67,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [
        {
          "id": 16364,
          "name": "Telephonic Interview",
          "parent_id": 16363,
          "sequence": 7,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": null,
          "WF": 67,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [
            {
              "id": 16365,
              "name": "Interview",
              "parent_id": 16364,
              "sequence": 8,
              "depends_on": [
                0
              ],
              "stage": 3,
              "status": "Completed",
              "form": {
                "id": 13,
                "form_name": "Telephonic Interview",
                "form_path": "Stepper/TelephonicInterviewRound/TelephonicRound"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            },
            {
              "id": 16366,
              "name": "Review",
              "parent_id": 16364,
              "sequence": 9,
              "depends_on": [
                16365
              ],
              "stage": 3,
              "status": "Completed",
              "form": {
                "id": 24,
                "form_name": "Telephonic Interview Review",
                "form_path": "Stepper/TelephonicInterviewRound/TelephonicInterviewReview"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            }
          ],
          "level": 1
        },
        {
          "id": 16367,
          "name": "Technical Interview",
          "parent_id": 16363,
          "sequence": 10,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": null,
          "WF": 67,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [
            {
              "id": 16368,
              "name": "Interview",
              "parent_id": 16367,
              "sequence": 11,
              "depends_on": [
                16366
              ],
              "stage": 0,
              "status": "Future Scheduled",
              "form": {
                "id": 11,
                "form_name": "Technical Interview",
                "form_path": "Stepper/TechnicalInterviewRound/TechnicalInterviewRound"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            },
            {
              "id": 16369,
              "name": "Review",
              "parent_id": 16367,
              "sequence": 12,
              "depends_on": [
                16368
              ],
              "stage": 0,
              "status": "Future Scheduled",
              "form": {
                "id": 22,
                "form_name": "Technical Interview Review",
                "form_path": "Stepper/TechnicalInterviewRound/TechnicalInterviewReview"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            }
          ],
          "level": 1
        }
      ],
      "level": 0
    },
    {
      "id": 16542,
      "name": "HR Round",
      "parent_id": 0,
      "sequence": 8,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": null,
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [
        {
          "id": 16543,
          "name": "HR Interview",
          "parent_id": 16542,
          "sequence": 9,
          "depends_on": [
            0
          ],
          "stage": 1,
          "status": "Scheduled",
          "form": {
            "id": 12,
            "form_name": "HR Interview",
            "form_path": "Stepper/HRInterviewRound/HRInterviewRound"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        },
        {
          "id": 16544,
          "name": "HR Interview Review",
          "parent_id": 16542,
          "sequence": 10,
          "depends_on": [
            0
          ],
          "stage": 1,
          "status": "Scheduled",
          "form": {
            "id": 25,
            "form_name": "HR Interview Review",
            "form_path": "Stepper/HRInterviewRound/HRInterviewReview"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        }
      ],
      "level": 0
    },
    {
      "id": 16545,
      "name": "Technical Round",
      "parent_id": 0,
      "sequence": 11,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": null,
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [
        {
          "id": 16546,
          "name": "Technical Interview",
          "parent_id": 16545,
          "sequence": 12,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": {
            "id": 11,
            "form_name": "Technical Interview",
            "form_path": "Stepper/TechnicalInterviewRound/TechnicalInterviewRound"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        },
        {
          "id": 16547,
          "name": "Technical Interview Review",
          "parent_id": 16545,
          "sequence": 13,
          "depends_on": [
            16546
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": {
            "id": 22,
            "form_name": "Technical Interview Review",
            "form_path": "Stepper/TechnicalInterviewRound/TechnicalInterviewReview"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        }
      ],
      "level": 0
    },
    {
      "id": 16370,
      "name": "Assessment",
      "parent_id": 0,
      "sequence": 13,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": null,
      "WF": 67,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [
        {
          "id": 16371,
          "name": "Assignment",
          "parent_id": 16370,
          "sequence": 14,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": null,
          "WF": 67,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [
            {
              "id": 16372,
              "name": "Assign",
              "parent_id": 16371,
              "sequence": 15,
              "depends_on": [
                16369
              ],
              "stage": 2,
              "status": "In progress",
              "form": {
                "id": 2,
                "form_name": "Assign Assignment",
                "form_path": "Stepper/AssignAssignment/AssignAssignment"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            },
            {
              "id": 16373,
              "name": "Upload",
              "parent_id": 16371,
              "sequence": 16,
              "depends_on": [
                16372
              ],
              "stage": 2,
              "status": "In progress",
              "form": {
                "id": 32,
                "form_name": "Assignment Upload",
                "form_path": "Stepper/AssignAssignment/AssignmentUpload"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": null,
              "tabs": [],
              "level": 2
            },
            {
              "id": 16374,
              "name": "Review",
              "parent_id": 16371,
              "sequence": 17,
              "depends_on": [
                16373
              ],
              "stage": 2,
              "status": "In progress",
              "form": {
                "id": 1,
                "form_name": "Assignment Review",
                "form_path": "Stepper/AssignmentReview/AssignmentReview"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            }
          ],
          "level": 1
        },
        {
          "id": 16375,
          "name": "Psychometric Test",
          "parent_id": 16370,
          "sequence": 18,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": null,
          "WF": 67,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [
            {
              "id": 16376,
              "name": "Assign",
              "parent_id": 16375,
              "sequence": 19,
              "depends_on": [
                16374
              ],
              "stage": 3,
              "status": "Completed",
              "form": {
                "id": 4,
                "form_name": "Test",
                "form_path": "Stepper/MettleTest/MettleTest"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            },
            {
              "id": 16377,
              "name": "Review",
              "parent_id": 16375,
              "sequence": 20,
              "depends_on": [
                16376
              ],
              "stage": 3,
              "status": "Completed",
              "form": {
                "id": 5,
                "form_name": "Test Review",
                "form_path": "Stepper/MettleTestReview/MettleTestReview"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            }
          ],
          "level": 1
        },
        {
          "id": 16378,
          "name": "Smart Hire Form",
          "parent_id": 16370,
          "sequence": 21,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": null,
          "WF": 67,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [
            {
              "id": 16379,
              "name": "Assign",
              "parent_id": 16378,
              "sequence": 22,
              "depends_on": [
                16377
              ],
              "stage": 3,
              "status": "Completed",
              "form": {
                "id": 14,
                "form_name": "Smart Hire Form",
                "form_path": "Stepper/SmartHireForm/SmartHireForm"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            },
            {
              "id": 16380,
              "name": "Review",
              "parent_id": 16378,
              "sequence": 23,
              "depends_on": [
                16379
              ],
              "stage": 3,
              "status": "Completed",
              "form": {
                "id": 23,
                "form_name": "Smart Hire Form Review",
                "form_path": "Stepper/SmartHireForm/SmartHireFormReview"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            }
          ],
          "level": 1
        }
      ],
      "level": 0
    },
    {
      "id": 16548,
      "name": "Assignment Round",
      "parent_id": 0,
      "sequence": 14,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": null,
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [
        {
          "id": 16549,
          "name": "Assign Assignment",
          "parent_id": 16548,
          "sequence": 15,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": {
            "id": 2,
            "form_name": "Assign Assignment",
            "form_path": "Stepper/AssignAssignment/AssignAssignment"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        },
        {
          "id": 16550,
          "name": "Assignment Review",
          "parent_id": 16548,
          "sequence": 16,
          "depends_on": [
            16549
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": {
            "id": 1,
            "form_name": "Assignment Review",
            "form_path": "Stepper/AssignmentReview/AssignmentReview"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        }
      ],
      "level": 0
    },
    {
      "id": 16551,
      "name": "Mettl Test Round",
      "parent_id": 0,
      "sequence": 17,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": null,
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [
        {
          "id": 16552,
          "name": "Mettl Test",
          "parent_id": 16551,
          "sequence": 18,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": {
            "id": 4,
            "form_name": "Test",
            "form_path": "Stepper/MettleTest/MettleTest"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        },
        {
          "id": 16553,
          "name": "Mettl Test Review",
          "parent_id": 16551,
          "sequence": 19,
          "depends_on": [
            16552
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": {
            "id": 5,
            "form_name": "Test Review",
            "form_path": "Stepper/MettleTestReview/MettleTestReview"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        }
      ],
      "level": 0
    },
    {
      "id": 16554,
      "name": "Smart Hire Form Round",
      "parent_id": 0,
      "sequence": 20,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": null,
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [
        {
          "id": 16555,
          "name": "Smart Hire Form",
          "parent_id": 16554,
          "sequence": 21,
          "depends_on": [
            0
          ],
          "stage": 1,
          "status": "Scheduled",
          "selection_status": 0,
          "form": {
            "id": 14,
            "form_name": "Smart Hire Form",
            "form_path": "Stepper/SmartHireForm/SmartHireForm"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        },
        {
          "id": 16556,
          "name": "Smart Hire Form Review",
          "parent_id": 16554,
          "sequence": 22,
          "depends_on": [
            16555
          ],
          "stage": 1,
          "status": "Scheduled",
          "selection_status": 1,
          "form": {
            "id": 23,
            "form_name": "Smart Hire Form Review",
            "form_path": "Stepper/SmartHireForm/SmartHireFormReview"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        }
      ],
      "level": 0
    },
    {
      "id": 16557,
      "name": "Know Your Candidate",
      "parent_id": 0,
      "sequence": 23,
      "depends_on": [
        0
      ],
      "stage": 3,
      "status": "Completed",
      "selection_status": 2,
      "form": {
        "id": 16,
        "form_name": "KYC",
        "form_path": "Stepper/KYC/KYC"
      },
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [],
      "level": 0
    },
    {
      "id": 16381,
      "name": "HRD Approval",
      "parent_id": 0,
      "sequence": 24,
      "depends_on": [
        16380
      ],
      "stage": 3,
      "status": "Completed",
      "selection_status": 2,
      "form": {
        "id": 15,
        "form_name": "HRD Approval",
        "form_path": "Stepper/HRDApproval/HRDApproval"
      },
      "WF": 67,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [],
      "level": 0
    },
    {
      "id": 16558,
      "name": "HRD Approval",
      "parent_id": 0,
      "sequence": 24,
      "depends_on": [
        0
      ],
      "stage": 3,
      "status": "Completed",
      "selection_status": 2,
      "form": {
        "id": 15,
        "form_name": "HRD Approval",
        "form_path": "Stepper/HRDApproval/HRDApproval"
      },
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [],
      "level": 0
    },
    {
      "id": 16382,
      "name": "MD Approval",
      "parent_id": 0,
      "sequence": 25,
      "depends_on": [
        16381
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": {
        "id": 3,
        "form_name": "MD Approval",
        "form_path": "Stepper/MDApproval/MDApproval"
      },
      "WF": 67,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [],
      "level": 0
    },
    {
      "id": 16559,
      "name": "MD Approval",
      "parent_id": 0,
      "sequence": 25,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": {
        "id": 3,
        "form_name": "MD Approval",
        "form_path": "Stepper/MDApproval/MDApproval"
      },
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [],
      "level": 0
    },
    {
      "id": 16383,
      "name": "Know Your Candidate",
      "parent_id": 0,
      "sequence": 26,
      "depends_on": [
        16382
      ],
      "stage": 3,
      "status": "Completed",
      "form": {
        "id": 16,
        "form_name": "KYC",
        "form_path": "Stepper/KYC/KYC"
      },
      "WF": 67,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [],
      "level": 0
    },
    {
      "id": 16384,
      "name": "Offer",
      "parent_id": 0,
      "sequence": 27,
      "depends_on": [
        0
      ],
      "stage": 3,
      "status": "Completed",
      "form": {
        "id": 6,
        "form_name": "Send Offer",
        "form_path": "Stepper/SendOffer/SendOffer"
      },
      "WF": 67,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [],
      "level": 0
    },
    {
      "id": 16560,
      "name": "Send Offer",
      "parent_id": 0,
      "sequence": 27,
      "depends_on": [
        0
      ],
      "stage": 3,
      "status": "Completed",
      "form": {
        "id": 6,
        "form_name": "Send Offer",
        "form_path": "Stepper/SendOffer/SendOffer"
      },
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [],
      "level": 0
    },
    {
      "id": 16385,
      "name": "Background Verification",
      "parent_id": 0,
      "sequence": 28,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": null,
      "WF": 67,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [
        {
          "id": 16386,
          "name": "Background Verification 1",
          "parent_id": 16385,
          "sequence": 29,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": null,
          "WF": 67,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [
            {
              "id": 16387,
              "name": "Upload",
              "parent_id": 16386,
              "sequence": 30,
              "depends_on": [
                0
              ],
              "stage": 0,
              "status": "Future Scheduled",
              "form": {
                "id": 26,
                "form_name": "Background Verification one",
                "form_path": "Stepper/BackgroundVerification/BGV1"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            },
            {
              "id": 16388,
              "name": "Review",
              "parent_id": 16386,
              "sequence": 31,
              "depends_on": [
                16387
              ],
              "stage": 0,
              "status": "Future Scheduled",
              "form": {
                "id": 29,
                "form_name": "Background Verification one Review",
                "form_path": "Stepper/BackgroundVerification/BGV1Review"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            }
          ],
          "level": 1
        },
        {
          "id": 16389,
          "name": "Background Verification 2",
          "parent_id": 16385,
          "sequence": 32,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": null,
          "WF": 67,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [
            {
              "id": 16390,
              "name": "Upload",
              "parent_id": 16389,
              "sequence": 33,
              "depends_on": [
                0
              ],
              "stage": 0,
              "status": "Future Scheduled",
              "form": {
                "id": 27,
                "form_name": "Background Verification two",
                "form_path": "Stepper/BackgroundVerification/BGV2"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            },
            {
              "id": 16391,
              "name": "Review",
              "parent_id": 16389,
              "sequence": 34,
              "depends_on": [
                0
              ],
              "stage": 0,
              "status": "Future Scheduled",
              "form": {
                "id": 30,
                "form_name": "Background Verification two Review",
                "form_path": "Stepper/BackgroundVerification/BGV2Review"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            }
          ],
          "level": 1
        },
        {
          "id": 16392,
          "name": "Background Verification 3",
          "parent_id": 16385,
          "sequence": 35,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": null,
          "WF": 67,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [
            {
              "id": 16393,
              "name": "Upload",
              "parent_id": 16392,
              "sequence": 36,
              "depends_on": [
                0
              ],
              "stage": 0,
              "status": "Future Scheduled",
              "form": {
                "id": 28,
                "form_name": "Background Verification three",
                "form_path": "Stepper/BackgroundVerification/BGV3"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            },
            {
              "id": 16394,
              "name": "Review",
              "parent_id": 16392,
              "sequence": 37,
              "depends_on": [
                0
              ],
              "stage": 0,
              "status": "Future Scheduled",
              "form": {
                "id": 31,
                "form_name": "Background Verification three Review",
                "form_path": "Stepper/BackgroundVerification/BGV3Review"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            }
          ],
          "level": 1
        }
      ],
      "level": 0
    },
    {
      "id": 16561,
      "name": "Background Verification Round 1",
      "parent_id": 0,
      "sequence": 28,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": null,
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [
        {
          "id": 16562,
          "name": "Background Verification 1",
          "parent_id": 16561,
          "sequence": 29,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": {
            "id": 26,
            "form_name": "Background Verification one",
            "form_path": "Stepper/BackgroundVerification/BGV1"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        },
        {
          "id": 16563,
          "name": "Background Verification Review 1",
          "parent_id": 16561,
          "sequence": 30,
          "depends_on": [
            16562
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": {
            "id": 29,
            "form_name": "Background Verification one Review",
            "form_path": "Stepper/BackgroundVerification/BGV1Review"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        }
      ],
      "level": 0
    },
    {
      "id": 16564,
      "name": "Background Verification Round 2",
      "parent_id": 0,
      "sequence": 31,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": null,
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [
        {
          "id": 16565,
          "name": "Background Verification 2",
          "parent_id": 16564,
          "sequence": 32,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": {
            "id": 27,
            "form_name": "Background Verification two",
            "form_path": "Stepper/BackgroundVerification/BGV2"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        },
        {
          "id": 16566,
          "name": "Background Verification Review 2",
          "parent_id": 16564,
          "sequence": 33,
          "depends_on": [
            16565
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": {
            "id": 30,
            "form_name": "Background Verification two Review",
            "form_path": "Stepper/BackgroundVerification/BGV2Review"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        }
      ],
      "level": 0
    },
    {
      "id": 16567,
      "name": "Background Verification Round 3",
      "parent_id": 0,
      "sequence": 34,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": null,
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [
        {
          "id": 16568,
          "name": "Background Verification 3",
          "parent_id": 16567,
          "sequence": 35,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": {
            "id": 28,
            "form_name": "Background Verification three",
            "form_path": "Stepper/BackgroundVerification/BGV3"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        },
        {
          "id": 16569,
          "name": "Background Verification Review 3",
          "parent_id": 16567,
          "sequence": 36,
          "depends_on": [
            16568
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": {
            "id": 31,
            "form_name": "Background Verification three Review",
            "form_path": "Stepper/BackgroundVerification/BGV3Review"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        }
      ],
      "level": 0
    }
  ],
  "sequence": [
    [
      0
    ],
    [
      1,
      0
    ],
    [
      1,
      1
    ],
    [
      1,
      2
    ],
    [
      1,
      3
    ],
    [
      2
    ],
    [
      3
    ],
    [
      4
    ],
    [
      5,
      0
    ],
    [
      5,
      1
    ],
    [
      6,
      0,
      0
    ],
    [
      6,
      0,
      1
    ],
    [
      6,
      1,
      0
    ],
    [
      6,
      1,
      1
    ],
    [
      7,
      0
    ],
    [
      7,
      1
    ],
    [
      8,
      0
    ],
    [
      8,
      1
    ],
    [
      9,
      0,
      0
    ],
    [
      9,
      0,
      1
    ],
    [
      9,
      0,
      2
    ],
    [
      9,
      1,
      0
    ],
    [
      9,
      1,
      1
    ],
    [
      9,
      2,
      0
    ],
    [
      9,
      2,
      1
    ],
    [
      10,
      0
    ],
    [
      10,
      1
    ],
    [
      11,
      0
    ],
    [
      11,
      1
    ],
    [
      12,
      0
    ],
    [
      12,
      1
    ],
    [
      13
    ],
    [
      14
    ],
    [
      15
    ],
    [
      16
    ],
    [
      17
    ],
    [
      18
    ],
    [
      19
    ],
    [
      20
    ],
    [
      21,
      0,
      0
    ],
    [
      21,
      0,
      1
    ],
    [
      21,
      1,
      0
    ],
    [
      21,
      1,
      1
    ],
    [
      21,
      2,
      0
    ],
    [
      21,
      2,
      1
    ],
    [
      22,
      0
    ],
    [
      22,
      1
    ],
    [
      23,
      0
    ],
    [
      23,
      1
    ],
    [
      24,
      0
    ],
    [
      24,
      1
    ]
  ]
};

export const session = {
  session: [
    {
      id: 1,
      session_id: "Null",
      tab_id: 0,
      wf: 1,
      user_id: 1,
    },
    {
      id: 2,
      session_id: "Null",
      tab_id: 140,
      wf: 1,
      user_id: 1,
    },
    {
      id: 3,
      session_id: "ABC",
      tab_id: 139,
      wf: 1,
      user_id: 1,
    },
  ],
};

export const configurablePrivilege = {
  session: [
    {
      id: 1,
      session_id: "Null",
      tab_id: 140,
      wf: 1,
      user_id: 1,
    },
    {
      id: 2,
      session_id: "ABC",
      tab_id: 139,
      wf: 1,
      user_id: 1,
    },
  ],
};

export const TamOptions = [
  {
    label: "Naukri",
    value: "naukri",
  },
  {
    label: "FSC Careers",
    value: "FSC_careers",
  },
  {
    label: "Indeed",
    value: "Indeed",
  },
  {
    label: "Linkedin",
    value: "Linkedin",
  },
];

export const RE2Options = [
  {
    label: "Naukri",
    value: "naukri",
  },
  {
    label: "Linkedin",
    value: "Linkedin",
  },
  {
    label: "Indeed",
    value: "Indeed",
  },
  {
    label: "Updaaz",
    value: "Updaaz",
  },
  {
    label: "Hirist",
    value: "Hirist",
  },
];

export const TypemasterData = {
  data: [
    {
      category: {
        category_name: "Department",
      },
      name: "Software S/W",
      value: "1",
    },
    {
      category: {
        category_name: "Department",
      },
      name: "Hardware",
      value: "2",
    },
  ],
};

export const Typemaster = {
  data: {
    Department: [
      {
        name: "Software S/W",
        value: "1",
      },
      {
        name: "Hardware",
        value: "2",
      },
    ],
    Job: [
      {
        name: "Engineer",
        value: "1",
      },
      {
        name: "QA",
        value: "2",
      },
    ],
  },
};

export const RCQuestions = [
  {
    label: "Overqualified",
  },
  {
    label: "Overexperienced",
  },
  {
    label: "High potential but for another position",
  },
  {
    label: "Insufficient experience",
  },
  {
    label: "Irrelevant experience",
  },
  {
    label: "Lack of required skills",
  },
  {
    label: "Banned",
  },
];

export const TI = [
  {
    interview_id: 1,
    questions_data: [
      { question: "TI 4", score: "1", comments: "C1" },
      { question: "TI 3", score: "2", comments: "C2" },
      { question: "TI 2", score: "3", comments: "C3" },
    ],
    categories: [
      { category: "Communication", manualMarks: 2 },
      { category: "Coding", manualMarks: 4 },
    ],
    total_score: 6,
    feedback: "Feedback",
    candidate_status: "Draft",
    created_by_id: 3,
    updated_by_id: 3,
    workflow_session: {
      candidate_job_id: null,
      user: 3,
      created_by_id: 3,
      updated_by_id: 3,
    },
  },
];

export const telephonicData = {
  "interview_details": {
    "id": 63,
    "interview_mode": "Online",
    "interviewer_name": [
      {
        "id": 8,
        "first_name": "Cameron",
        "last_name": "Leon"
      }
    ],
    "interview_type": "HR Telephonic",
    "comments": null,
    "total_score": 8.0,
    "candidate_accept_reject": null,
    "interview_action": 1,
    "requisition": {
      "id": 25,
      "required_experience": 4.0,
      "min_salary": 3,
      "max_salary": 4
    },
    "interview_datetime": "2024-09-18T00:00:00+05:30",
    "google_meet_link": "https://meet.google.com/jqs-xdma-kti"
  },
  "interview_question_score": [
    {
      "id": 236,
      "question": "Six Days Working",
      "answer": "Okay",
      "question_status": true,
      "score": 0.5,
      "comments": null
    },
    {
      "id": 237,
      "question": "Saturday & Sunday Rotational",
      "answer": "Okay",
      "question_status": true,
      "score": 0.5,
      "comments": null
    },
    {
      "id": 238,
      "question": "Location Suitability",
      "answer": "Good",
      "question_status": true,
      "score": 1.0,
      "comments": null
    },
    {
      "id": 239,
      "question": "Notice Period",
      "answer": "0",
      "question_status": true,
      "score": 1.0,
      "comments": null
    },
    {
      "id": 240,
      "question": "Flexible Notice Period",
      "answer": "Good",
      "question_status": true,
      "score": 1.0,
      "comments": null
    },
    {
      "id": 241,
      "question": "Relevant vs Required Experience",
      "answer": "3",
      "question_status": true,
      "score": 0.0,
      "comments": null
    },
    {
      "id": 242,
      "question": "Relevant Experience Score",
      "answer": "Good",
      "question_status": true,
      "score": 1.0,
      "comments": null
    },
    {
      "id": 243,
      "question": "Technical Profile vs Skillsets",
      "answer": "Okay",
      "question_status": true,
      "score": 0.5,
      "comments": null
    },
    {
      "id": 244,
      "question": "Relevant Academic Validation Score",
      "answer": "Good",
      "question_status": true,
      "score": 1.0,
      "comments": null
    },
    {
      "id": 245,
      "question": "Skillset to Profile Appropriation Score",
      "answer": "Good",
      "question_status": true,
      "score": 1.0,
      "comments": null
    },
    {
      "id": 246,
      "question": "Stability Score",
      "answer": "Okay",
      "question_status": true,
      "score": 0.5,
      "comments": null
    },
    {
      "id": 247,
      "question": "Candidate CTC vs Budget CTC",
      "answer": "3",
      "question_status": true,
      "score": 0.0,
      "comments": null
    },
    {
      "id": 248,
      "question": "Reasons to change the job",
      "answer": "no",
      "question_status": true,
      "score": 0.0,
      "comments": null
    }
  ],
  "interview_score": {
    "id": 33,
    "category": "HR Telephonic",
    "score": 8.0,
    "comments": null
  }
};

export const KYCDATA = [
  {
    id: 1,
    label: "AADHAR CARD",
    document: "done",
  },
  {
    id: 2,
    label: "PAN CARD",
    document: null,
  },
  {
    id: 3,
    label: "Appointment Letter/Offer Letter",
    document: null,
  },
  {
    id: 4,
    label: "Appraisal/Increment Letter",
    document: "done",
  },
  {
    id: 5,
    label: "Last three months salary slip",
    document: null,
  },
  {
    id: 6,
    label: "Last six months salary account bank statement",
    document: "done",
  },
  {
    id: 7,
    label: "Recent passport size photograph",
    document: "done",
  },
];

export const assignment = {
  "id": 13,
  "assignment_conducted_user": [
    {
      "id": 40,
      "first_name": "Nyssa",
      "last_name": "Rodgers"
    },
    {
      "id": 8,
      "first_name": "Cameron",
      "last_name": "Leon"
    },
    {
      "id": 10,
      "first_name": "Zahir",
      "last_name": "Calhoun"
    }
  ],
  "choose_assignment": {
    "id": 16,
    "job_opening": {
      "id": 241,
      "job_title": "Sr. Creative Director"
    },
    "assignment_title": "english test",
    "assignment_document": "https://fsc-rms.s3.ap-south-1.amazonaws.com/media/JobAssignment_document/Grey__White_Minimal_Resume_1.pdf"
  },
  "upload_assignment": "https://fsc-rms.s3.ap-south-1.amazonaws.com/media/Assignment_upload__assignment/sample-crack-of-dawn-resume.pdf",
  "feedback": null,
  "manager_feedback": null,
  "comments": "good to go",
  "candidate_feedback": null,
  "selection_status": 1,
  "total_score": null,
  "start_date": "2024-09-12",
  "end_date": "2024-09-14",
  "extended_end_date": null,
  "extend_request": false,
  "assignment_review_by": [
    {
      "user": {
        "id": 40,
        "first_name": "Zahir",
        "last_name": "Calhouns"
      },
      "comments": "no comment",
      "feedback": "Good to go",
      "total_score": 5.0,
      "selection_status": 1
    },
    {
      "user": {
        "id": 7,
        "first_name": "Nyssa",
        "last_name": "Rodgers"
      },
      "comments": "no comment",
      "feedback": "Yes i have",
      "total_score": 4.0,
      "selection_status": 1
    }
  ]
}

export const technicalDetails = {
  "interview_details": {
    "id": 65,
    "interview_mode": "Online",
    "interviewer_name": [
      {
        "id": 7,
        "first_name": "Nyssa",
        "last_name": "Rodgers"
      }
    ],
    "interview_type": "Technical_1",
    "comments": "good to go",
    "total_score": null,
    "candidate_accept_reject": 1,
    "interview_action": null,
    "requisition": {
      "id": 25,
      "required_experience": 4.0,
      "min_salary": 3,
      "max_salary": 4
    },
    "interview_datetime": "2024-09-17T21:51:00+05:30",
    "google_meet_link": "https://meet.google.com/xoc-dgev-bhj",
    "interview_feedback": [
      {
        "id": 15,
        "interviewer": {
          "id": 7,
          "first_name": "Nyssa",
          "last_name": "Rodgers"
        },
        "total_score": 11.0,
        "comments": "no ",
        "candidate_accept_reject": 1,
        "categories_data": [
          {
            "id": 34,
            "category": "Communication",
            "score": 6.0,
            "comments": null
          },
          {
            "id": 35,
            "category": "Pre-screening-test",
            "score": 5.0,
            "comments": null
          }
        ],
        "questions_data": [
          {
            "id": 249,
            "question": "What is effective communication",
            "answer": null,
            "question_status": null,
            "score": 6.0,
            "comments": "no"
          },
          {
            "id": 250,
            "question": "What is HTML",
            "answer": null,
            "question_status": null,
            "score": 5.0,
            "comments": ""
          }
        ]
      },
      {
        "id": 16,
        "interviewer": {
          "id": 10,
          "first_name": "Zahir",
          "last_name": "Calhoun"
        },
        "total_score": 7.0,
        "comments": "",
        "candidate_accept_reject": 1,
        "categories_data": [
          {
            "id": 36,
            "category": "Communication",
            "score": 4.0,
            "comments": null
          },
          {
            "id": 37,
            "category": "Pre-screening-test",
            "score": 3.0,
            "comments": null
          }
        ],
        "questions_data": [
          {
            "id": 251,
            "question": "What is effective communication",
            "answer": null,
            "question_status": null,
            "score": 5.0,
            "comments": ""
          },
          {
            "id": 252,
            "question": "What is HTML",
            "answer": null,
            "question_status": null,
            "score": 3.0,
            "comments": ""
          }
        ]
      }
    ]
  }
};

export const candidateExam = [
  {
    "id": 10,
    "test_name": "phycometric test",
    "test_link": "https://google.com",
    "test_conducted_user": {
      "id": 8,
      "first_name": "Cameron",
      "last_name": "Leon"
    },
    "comments": "no",
    "selection_status": 0,
    "test_done": null,
    "upload_test": "https://fsc-rms.s3.ap-south-1.amazonaws.com/media/CandidateExam_upload_test/sample-crack-of-dawn-resume.pdf",
    "score": null
  }
]

export const SHFData = [
  {
    "interview_details": {
      "id": 64,
      "interview_mode": "Online",
      "interviewer_name": [],
      "interview_type": "SHF",
      "comments": "no ",
      "total_score": 0.0,
      "candidate_accept_reject": null,
      "interview_action": null,
      "requisition": {
        "id": 25,
        "required_experience": 4.0,
        "min_salary": 3,
        "max_salary": 4
      },
      "interview_datetime": null,
      "google_meet_link": null
    },
    "interview_question_score": [
      {
        "id": 253,
        "question": "Name",
        "answer": "Lina Deshpande",
        "question_status": true,
        "score": 0.0,
        "comments": null
      },
      {
        "id": 254,
        "question": "Email",
        "answer": "lina.dp@gmail.com",
        "question_status": true,
        "score": 0.0,
        "comments": null
      }
    ]
  }
]

export const candidateAdditionalData = {
  "id": 54,
  "job_opening_id": 241,
  "user": {
    "id": 68,
    "first_name": "Lina",
    "last_name": "Deshpande"
  },
  "shortlisted_user_id": null,
  "candidate_resume_id": 94,
  "notice_period": null,
  "current_previous_company": null,
  "expected_salary": null,
  "experience": null,
  "is_draft": false,
  "is_shortlisted": true,
  "md_score": 5.0,
  "md_approval": null,
  "md_comments": "no",
  "md_feedback": "Good to go",
  "hrd_approval": null,
  "hrd_comments": "Yes good to go",
  "hrd_feedback": "Good",
  "hrd_score": 5.0,
  "view_resume_count": null,
  "activity_status": 2,
  "created_at": "2024-09-12T15:58:00.873675+05:30",
  "updated_at": "2024-09-16T16:06:59.683373+05:30",
  "HR_Telephonic": {
    "id": 63,
    "interview_mode": "Online",
    "interviewer_name": [
      {
        "id": 8,
        "first_name": "Cameron",
        "last_name": "Leon"
      }
    ],
    "interview_type": "HR Telephonic",
    "interview_date": null,
    "interview_time": null,
    "interview_datetime": "2024-09-18T00:00:00+05:30",
    "comments": null,
    "total_score": 8.0,
    "candidate_accept_reject": 1,
    "interview_action": null,
    "google_meet_link": "https://meet.google.com/jqs-xdma-kti"
  },
  "SHF": {
    "id": 64,
    "interview_mode": "Online",
    "interviewer_name": [],
    "interview_type": "SHF",
    "interview_date": null,
    "interview_time": null,
    "interview_datetime": null,
    "comments": "no ",
    "total_score": 0.0,
    "candidate_accept_reject": 1,
    "interview_action": null,
    "google_meet_link": null
  },
  "Technical": {
    "id": 66,
    "interview_mode": "Online",
    "interviewer_name": [
      {
        "id": 7,
        "first_name": "Nyssa",
        "last_name": "Rodgers"
      }
    ],
    "interview_type": "Technical_2",
    "interview_date": null,
    "interview_time": null,
    "interview_datetime": null,
    "comments": null,
    "total_score": null,
    "candidate_accept_reject": null,
    "interview_action": null,
    "google_meet_link": "https://meet.google.com/kyp-uecc-yuc"
  },
  "assignment_data": {
    "id": 13,
    "assignment_conducted_user": [
      {
        "id": 7,
        "first_name": "Nyssa",
        "last_name": "Rodgers"
      },
      {
        "id": 8,
        "first_name": "Cameron",
        "last_name": "Leon"
      },
      {
        "id": 10,
        "first_name": "Zahir",
        "last_name": "Calhoun"
      }
    ],
    "upload_assignment": "https://fsc-rms.s3.ap-south-1.amazonaws.com/media/Assignment_upload__assignment/sample-crack-of-dawn-resume.pdf",
    "feedback": null,
    "manager_feedback": null,
    "comments": "good to go",
    "candidate_feedback": null,
    "selection_status": 1,
    "total_score": null,
    "start_date": "2024-09-12",
    "end_date": "2024-09-14",
    "extended_end_date": null,
    "extend_request": false
  },
  "exam_data": {
    "id": 10,
    "test_name": "phycometric test",
    "test_link": "https://google.com",
    "test_conducted_user": {
      "id": 8,
      "first_name": "Cameron",
      "last_name": "Leon"
    },
    "comments": "no",
    "selection_status": 1,
    "test_done": null,
    "upload_test": "https://fsc-rms.s3.ap-south-1.amazonaws.com/media/CandidateExam_upload_test/sample-crack-of-dawn-resume.pdf",
    "score": null
  },
  "kyc_data": {
    "id": 19,
    "requisition": 25,
    "candidate": 54,
    "documents": [
      {
        "id": 31,
        "document_name": "photo",
        "document": "https://fsc-rms.s3.ap-south-1.amazonaws.com/media/candidate_documents/sample-crack-of-dawn-resume.pdf"
      },
      {
        "id": 32,
        "document_name": "six_months_salary_slip",
        "document": "https://fsc-rms.s3.ap-south-1.amazonaws.com/media/candidate_documents/sample-smart-and-secure-resume.pdf"
      },
      {
        "id": 33,
        "document_name": "three_months_salary_slip",
        "document": "https://fsc-rms.s3.ap-south-1.amazonaws.com/media/candidate_documents/report.png"
      },
      {
        "id": 34,
        "document_name": "increment_letter",
        "document": "https://fsc-rms.s3.ap-south-1.amazonaws.com/media/candidate_documents/sample-smart-and-secure-resume_ZVdv7vK.pdf"
      },
      {
        "id": 35,
        "document_name": "offer_letter",
        "document": "https://fsc-rms.s3.ap-south-1.amazonaws.com/media/candidate_documents/sample-smart-and-secure-resume_UQX4Bdy.pdf"
      },
      {
        "id": 36,
        "document_name": "pan_card",
        "document": "https://fsc-rms.s3.ap-south-1.amazonaws.com/media/candidate_documents/sample-smart-and-secure-resume_wFWQ8QG.pdf"
      },
      {
        "id": 37,
        "document_name": "aadhar_card",
        "document": "https://fsc-rms.s3.ap-south-1.amazonaws.com/media/candidate_documents/sample-corporate-resume_WPh3ni4.pdf"
      }
    ]
  }
}

export const requisitionByUserData = [
  {
    "id": 435,
    "job_opening": {
      "id": 328,
      "job_title": "Exec. Paid Media Specialist",
      "department": {
        "id": 6,
        "department_name": "Digital Marketing Dept.",
        "department_display_name": "Digital Marketing Dept.",
        "department_incharge": null
      },
      "team": {
        "id": 9,
        "team_name": "Social Media Team_DM",
        "team_display_name": "Social Media Team_DM",
        "team_incharge": null
      },
      "position": {
        "id": 236,
        "position_name": "Paid Media Specialist",
        "position_display_name": "Paid Media Specialist"
      },
      "seniority": {
        "id": 3,
        "seniority_name": "Exec.",
        "seniority_display_name": "Exec.",
        "sequence": 2
      },
      "job_description": "<p><span style=\"color: rgb(4, 12, 40);\">Defining, measuring, and evaluating relevant paid media KPIs</span><span style=\"color: rgb(32, 33, 36);\">. Building out media buys for various ad platforms and overseeing the day-to-day execution of paid media. Overseeing and managing the overall budget for paid media. Conducting in-depth keyword and website research, ad grouping and audience targeting.</span></p>",
      "job_requirement": "<p><span style=\"color: rgb(4, 12, 40);\">Defining, measuring, and evaluating relevant paid media KPIs</span><span style=\"color: rgb(32, 33, 36);\">. Building out media buys for various ad platforms and overseeing the day-to-day execution of paid media. Overseeing and managing the overall budget for paid media. Conducting in-depth keyword and website research, ad grouping and audience targeting.</span></p>",
      "workflow": {
        "id": 69,
        "workflow_name": "Workflow - 3",
        "workflow_category": "Candidate"
      },
      "experience": 5.0,
      "location": "Kolkata",
      "vacancy": null,
      "seo_title": "Exec. Paid Media Specialist",
      "seo_keywords": "Exec. Paid Media Specialist",
      "seo_meta_key": "Exec. Paid Media Specialist",
      "url_required": "Exec. Paid Media Specialist",
      "activity_status": 0
    },
    "created_at": "2024-08-10T16:28:08.659540+05:30",
    "no_of_vacancy": 1,
    "job_type": "Part-time",
    "job_location": "Pune",
    "required_experience": 1.0,
    "min_salary": 2,
    "max_salary": 3,
    "deadline": null,
    "status": 0,
    "priority": "High",
    "comments": "Comments",
    "created_since": 1,
    "hrd_approved": 1,
    "md_approved": 2,
    "total_resume": 4,
    "candidate_in_process": { "received": 1, "processed": 3 },
    "shortlisted_candidate": { "received": 2, "processed": 3 },
    "sme_shortlisted_candidate": { "received": 3, "processed": 2 },
    "telephonic_candidates": { "received": 3, "processed": 1 },
    "technical_candidates": { "received": 4, "processed": 1 },
    "hr_candidates": { "received": 2, "processed": 0 },
    "assignment_candidates": { "received": 5, "processed": 0 },
    "test_candidates": { "received": 6, "processed": 0 },
    "shf_candidates": { "received": 7, "processed": 1 },
    "bgv_candidates": { "received": 8, "processed": 0 }
  }
]

export const tamInterviewSlots = [
  {
    "interviewer": {
      "id": 1,
      "first_name": "Test",
      "last_name": "User",
      "slots": [
        {
          "id": 21,
          "requisition": {
            "id": 47,
            "job_opening": {
              "id": 586,
              "job_title": "Sr. Operations Manager_CMD"
            }
          },
          "slot_duration": 1.0,
          "slot_start_time": "2024-08-27T01:00:00+05:30",
          "slot_end_time": "2024-08-27T02:00:00+05:30",
          "is_booked": false,
          "slot_accepted_by": {
            "id": 697,
            "first_name": "Aaradhya",
            "last_name": "Jaiswal"
          }
        },
        {
          "id": 22,
          "requisition": {
            "id": 47,
            "job_opening": {
              "id": 586,
              "job_title": "Sr. Operations Manager_CMD"
            }
          },
          "slot_duration": 1.0,
          "slot_start_time": "2024-08-07T01:00:00+05:30",
          "slot_end_time": "2024-08-07T02:00:00+05:30",
          "is_booked": false,
          "slot_accepted_by": null
        }
      ],
    }
  }
]

export const rawNotificationMapper = [
  {
    "id": 25,
    "stage": "Awaiting for MD Approval",
    "notification_message": "Requisition awaiting for MD/HRD approval",
    "specific_user": true,
    "is_active": false,
    "user": [
      {
        "id": 4,
        "first_name": "hrd",
        "last_name": "tw"
      },
      {
        "id": 5,
        "first_name": "md",
        "last_name": "tw"
      }
    ],
    "role": [
      {
        "id": 17,
        "role_name": "Superadmin",
        "role_value": "superadmin",
        "level": 1,
        "landing_page": "My Requisition",
        "landing_page_path": "/"
      }
    ]
  },
  {
    "id": 24,
    "stage": "Awaiting for HRD Approval",
    "notification_message": "Requisition awaiting for HRD/MD approval",
    "specific_user": false,
    "is_active": true,
    "user": [],
    "role": [
      {
        "id": 19,
        "role_name": "HR Director",
        "role_value": "HRD",
        "level": 3,
        "landing_page": "HR-D Approval",
        "landing_page_path": "/hrd-approval"
      },
      {
        "id": 28,
        "role_name": "Candidate",
        "role_value": "candidate",
        "level": 13,
        "landing_page": null,
        "landing_page_path": null
      }
    ]
  }
]

export const rawWhatsAppNotification = [
  {
    "id": 2,
    "stage": "Requisition Raised",
    "template_id": "HXe12cfc70bfefafb41e9a9afd082cad07",
    "template_message": "Hello {{1}},A new requisition for the {{2}} position has been raised and is pending your approval. Please review the requisition at your earliest convenience.Best regards,{{3}}",
    "specific_user": true,
    "is_active": false,
    "user": [
      {
        "id": 4,
        "first_name": "hrd",
        "last_name": "tw"
      },
      {
        "id": 5,
        "first_name": "md",
        "last_name": "tw"
      }
    ],
    "role": [
      {
        "id": 17,
        "role_name": "Superadmin",
        "role_value": "superadmin",
        "level": 1,
        "landing_page": "My Requisition",
        "landing_page_path": "/"
      }
    ]
  },
  {
    "id": 1,
    "stage": "Requisition Approved",
    "template_id": "HX962428435e43f911713b001ae62a545a",
    "template_message": "Hello {{1}},The requisition for the {{2}} position has been approved and is now pending assignment to RE1-2-3. Please proceed with the assignment process.Best regards,{{3}}",
    "specific_user": false,
    "is_active": true,
    "user": [],
    "role": [
      {
        "id": 18,
        "role_name": "MD",
        "role_value": "MD",
        "level": 2,
        "landing_page": "MD Approval",
        "landing_page_path": "/md-approval"
      },
      {
        "id": 19,
        "role_name": "HR Director",
        "role_value": "HRD",
        "level": 3,
        "landing_page": "HR-D Approval",
        "landing_page_path": "/hrd-approval"
      },
      {
        "id": 21,
        "role_name": "TAM",
        "role_value": "TAM",
        "level": 5,
        "landing_page": "Assess Requistions",
        "landing_page_path": "/assess-requisitions"
      }
    ]
  }
]

export const rawEmailNotification = [
  {
    "id": 4,
    "email_type": "Requisition Raised",
    "is_active": false,
    "subject": "New Requisition Raised",
    "message": " Dear {username},\r\n\r\nNew Requisition has been raised for job {job_title} for department {department}. Your approval is required to proceed with this requisition. Dear {username},\r\n\r\nNew Requisition has been raised for job {job_title} for department {department}. Your approval is required to proceed with this requisition.Dear {username},\r\n\r\nNew Requisition has been raised for job {job_title} for department {department}. Your approval is required to proceed with this requisition.Dear {username},\r\n\r\nNew Requisition has been raised for job {job_title} for department {department}. Your approval is required to proceed with this requisition.",
    "bcc_specific_user": true,
    "bcc_user": [
      {
        "id": 3,
        "first_name": "tam",
        "last_name": "tw"
      }
    ],
    "bcc_role": [
      {
        "id": 29,
        "role_name": "Career",
        "role_value": "career",
        "level": 14,
        "landing_page": null,
        "landing_page_path": null
      }
    ],
    "cc_specific_user": true,
    "cc_user": [
      {
        "id": 6,
        "first_name": "departmental manager",
        "last_name": "tw"
      }
    ],
    "cc_role": [
      {
        "id": 20,
        "role_name": "Departmental Manager",
        "role_value": "departmental_manager",
        "level": 4,
        "landing_page": "My Requisition",
        "landing_page_path": "/"
      }
    ],
    "to_specific_user": true,
    "to_user": [
      {
        "id": 2,
        "first_name": "superadmin",
        "last_name": "tw"
      },
      {
        "id": 3,
        "first_name": "tam",
        "last_name": "tw"
      }
    ],
    "to_role": [
      {
        "id": 28,
        "role_name": "Candidate",
        "role_value": "candidate",
        "level": 13,
        "landing_page": null,
        "landing_page_path": null
      },
      {
        "id": 29,
        "role_name": "Career",
        "role_value": "career",
        "level": 14,
        "landing_page": null,
        "landing_page_path": null
      }
    ]
  },
  {
    "id": 3,
    "email_type": "Candidate Shortlisted",
    "is_active": true,
    "subject": "Congratulations! You’ve Been Shortlisted – Next Steps to Move Forward",
    "message": "Dear {candidate_name},\r\n\r\nCongratulations! We are pleased to inform you that you have been shortlisted for the next stage of our recruitment process at Fluidscapes.\r\n\r\nTo proceed, we kindly request you to log in to our career portal and complete your personal, educational, and professional details. Your credentials for logging in are provided below:\r\n\r\nEmail: {email}\r\nPassword: {password}\r\nCareer Portal: career-page-test.techwalnut.co.in\r\n\r\n",
    "bcc_specific_user": false,
    "bcc_user": [],
    "bcc_role": [],
    "cc_specific_user": false,
    "cc_user": [],
    "cc_role": [],
    "to_specific_user": false,
    "to_user": [],
    "to_role": []
  }
]