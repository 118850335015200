import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import secureLocalStorage from "react-secure-storage";
import { googleLogout } from "@react-oauth/google";
import { ActivityLogsContext } from "../Context/ActivityLogsContext";
import { NotificationsContext } from "../Context/NotificationsContextProvider";

const Header = (props) => {
  const email = secureLocalStorage.getItem("email");
  const first_name = secureLocalStorage.getItem("firstName");
  const last_name = secureLocalStorage.getItem("lastName");
  const role = secureLocalStorage.getItem("role");
  console.log(role);
  const privileges = secureLocalStorage.getItem("privileges");
  const navigate = useNavigate();

  const [showAllRoles, setShowAllRoles] = useState(false);

  const { handlemenu, rawMenu, isMenuLoading } =
    useContext(ActivityLogsContext);

  const {
    notifications,
    unreadNotificationsCount,
    handleNotificationStatus,
    updateNotificationStatus,
    setUnreadNotificationsCount,
    retryCount,
    getNotifications,
    getAllNotifications,
  } = useContext(NotificationsContext);

  const handleNavigation = (n) => {
    navigate(n.redirection_page_path)
  }

  // Calling logout function in app.js. State Pull up
  const handleLogout = () => {
    googleLogout();
    props.logoutHandler();
    navigate("/");
  };

  const handleShowAllRoles = () => {
    setShowAllRoles((prev) => !prev);
  };

  const handleBlur = () => {
    setShowAllRoles(false);
  };

  useEffect(() => {
    handlemenu();
  }, []);

  useEffect(() => {
    console.log(retryCount);
    if (retryCount !== 0) {
      // enqueueSnackbar("No connection!", {
      //   anchorOrigin: {
      //     vertical: "bottom",
      //     horizontal: "center",
      //   },
      //   variant: "error",
      //   persist: true,
      //   preventDuplicate: true,
      // });
    } else {
      // closeSnackbar();
    }
  }, [retryCount]);

  useEffect(() => {
    setUnreadNotificationsCount(notifications.length);
  }, [notifications]);

  useEffect(() => {
    getNotifications();
    getAllNotifications();
  }, []);

  return (
    <>
      <div className="nk-header is-light nk-header-fixed is-light">
        <div className="container-xl wide-xl">
          <div className="nk-header-wrap">
            <div
              className="nk-menu-trigger d-xl-none ms-n1 me-3"
              id="button-button-sidebar_open"
            >
              <a
                href="javascript:void(0)"
                className="nk-nav-toggle nk-quick-nav-icon"
                data-target="sidebarMenu"
              >
                <em className="icon ni ni-menu"></em>
              </a>
            </div>
            <div className="nk-header-brand d-xl-none bg-blue rounded-3 p-2">
              <img
                src={require("../../Resources/images/RMS-Logo.png")}
                style={{ width: "6rem", maxHeight: "5rem" }}
              />
            </div>
            <div className="nk-header-tools">
              <ul className="nk-quick-nav">
                <li className="dropdown language-dropdown d-none d-sm-block me-n1">
                  <a
                    href="#"
                    className="dropdown-toggle nk-quick-nav-icon"
                    data-bs-toggle="dropdown"
                  >
                    <div className="quick-icon">
                      <div className="icon-status icon-status-na">
                        <em className="icon ni ni-view-grid-fill"></em>
                      </div>
                    </div>
                  </a>
                  <div className="dropdown-menu dropdown-menu-end">
                    <ul
                      className="language-list"
                      style={{ maxHeight: "250px", overflowY: "auto" }}
                    >
                      {!isMenuLoading ? (
                        rawMenu.length !== 0 ? (
                          rawMenu.map((menu, i) => (
                            <li>
                              <a href={menu?.url} className="language-item" target="_blank">
                                {menu?.logo ? (
                                  <img
                                    src={menu?.logo}
                                    alt=""
                                    className="language-flag"
                                  />
                                ) : (
                                  <div className="user-avatar sm me-2">
                                    <span>
                                      {menu?.app_display_name
                                        .charAt(0)
                                        .toUpperCase()}
                                    </span>
                                  </div>
                                )}

                                <span className="language-name">
                                  {menu?.app_display_name}
                                </span>
                              </a>
                            </li>
                          ))
                        ) : (
                          <>
                            <li>
                              <p className="language-item">
                                <span className="language-name">No Data</span>
                              </p>
                            </li>
                          </>
                        )
                      ) : (
                        <>
                          <li>
                            <p className="language-item">
                              <span className="language-name">Loading...</span>
                            </p>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </li>

                {/* <li className="me-n1 d-flex align-center justify-content-center">
                  <em className="icon ni ni-bell-fill fs-2 text-info"></em>
                  <a
                    href="#"
                    className="dropdown-toggle nk-quick-nav-icon"
                    data-bs-toggle="dropdown"
                  >
                  </a>
                </li> */}
                {privileges.includes("new_ticket") ? (
                  <div class="dropdown notification-dropdown me-1">
                    <div
                      type="button"
                      className="dropdown-toggle btn-sm position-relative"
                      data-bs-toggle="dropdown"
                      data-bs-display="static"
                      data-bs-auto-close="outside"
                      style={{ fontSize: "1em" }}
                    >
                      <em class="icon ni ni-bell-fill fs-2"></em>
                      {unreadNotificationsCount !== 0 && (
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                          {unreadNotificationsCount <= 99
                            ? unreadNotificationsCount.toString()
                            : "99+"}
                          <span className="visually-hidden">unread messages</span>
                        </span>
                      )}
                    </div>
                    <div class="dropdown-menu dropdown-menu-xl dropdown-menu-end">
                      <div class="dropdown-head">
                        <span class="sub-title nk-dropdown-title">Notifications</span>
                        <span
                          type="button"
                          className="btn btn-sm btn-outline-primary border-0"
                          onClick={updateNotificationStatus}
                        >
                          <em class="icon ni ni-repeat-v"></em>
                        </span>
                      </div>
                      <div class="dropdown-body">
                        <div
                          class="nk-notification"
                          style={{ minHeight: "300px" }}
                        >
                          {/* Dropdown items */}
                          {notifications.length !== 0 ? (
                            notifications.map((n, i) => {
                              return (
                                <div
                                  key={i}
                                  class="nk-notification-item dropdown-inner"
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor: n?.is_read
                                      ? "#fff"
                                      : "#bddfff",
                                    position: "relative",
                                  }}
                                  onClick={() => {
                                    handleNavigation(n)
                                    handleNotificationStatus(n.id)
                                  }
                                  }
                                >
                                  <div class="nk-notification-content">
                                    <div
                                      class="nk-notification-text"
                                      style={{
                                        fontSize: "1.1em",
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      {n.title}
                                    </div>
                                    <div class="nk-notification-text">
                                      {n.message}
                                    </div>
                                    <div class="nk-notification-time">
                                      {moment(new Date(n.created_at)).format(
                                        "Do MMMM YYYY"
                                      )}
                                    </div>
                                  </div>
                                  {!n?.is_read && (
                                    <div
                                      style={{
                                        height: "6px",
                                        width: "6px",
                                        borderRadius: "50%",
                                        backgroundColor: "#036ffc",
                                        position: "absolute",
                                        top: "10px",
                                        right: "10px",
                                      }}
                                    ></div>
                                  )}
                                </div>
                              );
                            })
                          ) : (
                            <>
                              <div class="nk-notification-item dropdown-inner">
                                <div class="nk-notification-content">
                                  <div class="nk-notification-text text-secondary">
                                    Empty
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div class="dropdown-foot center">
                        <Link to="/tickets">View All</Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <li
                  className="dropdown user-dropdown"
                  id="button-button-dropdown"
                >
                  <a
                    href="javascript:void(0)"
                    className="dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <div className="user-toggle">
                      <div className="user-avatar sm">
                        <em className="icon ni ni-user-alt"></em>
                      </div>
                    </div>
                  </a>
                  {/* <div
                    className="dropdown-menu dropdown-menu-md dropdown-menu-end"
                    onClick={(e) => e.stopPropagation()}
                  > */}
                  <div
                    className="dropdown-menu dropdown-menu-md dropdown-menu-end"
                    onClick={(e) => e.stopPropagation()}
                    onBlur={handleBlur}
                    tabIndex="0"
                  >
                    <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                      <div
                        className="user-card"
                        style={{ alignItems: "normal" }}
                      >
                        <div className="user-avatar">
                          <span>
                            {first_name?.charAt(0).toUpperCase() +
                              last_name?.charAt(0).toUpperCase()}
                          </span>
                        </div>
                        <div className="user-info">
                          <span className="lead-text">
                            {first_name[0].toUpperCase() +
                              first_name.slice(1) +
                              " " +
                              last_name[0].toUpperCase() +
                              last_name.slice(1)}
                          </span>
                          <span className="sub-text">{email}</span>
                          {role?.length > 1 ? (
                            <>
                              {!showAllRoles && (
                                <span className="lead-text text-info">
                                  {role[0]?.role_name}
                                  <a
                                    className="badge bg-info ms-1"
                                    style={{ cursor: "pointer" }}
                                    onClick={handleShowAllRoles}
                                  >
                                    +{role?.length - 1}
                                  </a>
                                </span>
                              )}
                              {showAllRoles &&
                                role.map((info) => (
                                  <span className="lead-text text-info">
                                    {info?.role_name}
                                  </span>
                                ))}
                            </>
                          ) : (
                            <span className="lead-text text-info">
                              {role ? role[0]?.role_name : ""}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="dropdown-inner">
                      <ul className="link-list">
                        <li id="button-button-signout">
                          <a href="javascript:void(0)" onClick={handleLogout}>
                            <em className="icon ni ni-signout"></em>
                            <span>Sign out</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
