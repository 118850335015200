export const VARIABLES = {
  // for developer
  // url: "http://64.226.76.196:8020",
  // rms_login : "rms-nxgp",
  // rms_id : 2

  //for tester
  url: "https://rms-api-test.techwalnut.co.in",
  //url: "http://127.0.0.1:8011",
  rms_login: "rms-fpcw",
  rms_id: 1,
  forgot_password_link: "https://rms-api-test.techwalnut.co.in/reset-password/",
};
