import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import secureLocalStorage from "react-secure-storage";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { VARIABLES } from "../../Constants";
import { JobAssignmentContext } from "../../Context/JobAssignment";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import ReactPaginate from "react-paginate";

const AddJobAssignment = () => {
  const { RefreshToken } = useContext(RefreshTokenContext);
  const privileges = secureLocalStorage.getItem("privileges");
  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const { isJobAssignmentLoading, jobAssignmentData, getJobAssignmentData } =
    useContext(JobAssignmentContext);
  console.log(jobAssignmentData);

  const [assignmentDocument, setAssignmentDocument] = useState(null);
  console.log("assignmentDocument:", assignmentDocument);
  const [filePreview, setFilePreview] = useState(null);

  const [assignmentCheck, setAssignmentCheck] = useState([]);
  console.log(assignmentCheck);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const handleResumeChange = (files) => {
    const fileType = files[0]?.type;

    if (fileType === "application/pdf") {
      setFilePreview(URL.createObjectURL(files[0]));
    } else {
      setFilePreview(null);
    }
  };

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(2);

  const handleEntries = (event) => {
    setItemsPerPage(event.target.value);
  };

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(assignmentCheck.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(assignmentCheck.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, assignmentCheck]);

  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage % assignmentCheck.length;
    setItemOffset(newOffset);
  };

  const onSubmit = (data) => {
    const access = secureLocalStorage.getItem("access");
    console.log(data);
    let user = new FormData();
    user.append("job_opening_id", location.state.editpost.id);
    user.append("assignment_title", data.assignment_title);
    user.append("assignment_document", data.assignment_document);
    user.append("updated_by_id", userID);
    user.append("created_by_id", userID);

    let config = {
      method: "post",
      url: VARIABLES.url + `/api/jobassignment/`,
      maxBodyLength: Infinity,
      headers: {
        Authorization: `Bearer ${access}`,
      },
      data: user,
    };

    console.log(config);

    for (var pair of user.entries()) {
      console.log(pair);
    }

    setSubmitLoading(true);
    axios
      .request(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        getJobAssignmentData();
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "successfully created assignment",
        });
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 403) {
          setSubmitLoading(true);
          RefreshToken();
        } else {
          setSubmitLoading(false);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: "Failed to create assignment",
          });
        }
      });
  };

  useEffect(() => {
    const handleAssignmentCheck = jobAssignmentData.filter(
      (info) => info.job_opening.id === location.state.editpost.id
    );
    setAssignmentCheck(handleAssignmentCheck);
    console.log(location.state.editpost);
  }, []);

  useEffect(() => {
    getJobAssignmentData();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">Job Assignment</h3>
            {isJobAssignmentLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div class="nk-block-head-content">
            <a
              id="button-back"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="button-back"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Assignment Title <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="text-forms-assignment_title"
                      placeholder="Enter assignment title"
                      {...register("assignment_title", {
                        required: true,
                        // pattern: /^[A-Za-z\s]+$/i,
                      })}
                    />
                    {errors.assignment_title && (
                      <span className="fs-8 text-danger">
                        Assignment title is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Assignment Document
                      <span className="fs-8 text-danger">*</span>
                    </label>
                    <Controller
                      name="assignment_document"
                      control={control}
                      rules={{
                        required: true,
                        validate: (value) => value.type === "application/pdf",
                      }}
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          className="form-control"
                          id="file-forms-assignment_document"
                          type="file"
                          onChange={(e) => {
                            field.onChange(e.target.files[0]);
                            setAssignmentDocument(e.target.files[0]);
                            handleResumeChange(e.target.files);
                          }}
                        />
                      )}
                    />
                    {errors.assignment_document && errors.assignment_document.type === "required" && (
                      <span className="fs-8 text-danger">Assignment document is required</span>
                    )}
                    {errors.assignment_document && errors.assignment_document.type === "validate" && (
                      <span className="fs-8 text-danger">
                        Please upload your document in pdf format
                      </span>
                    )}
                    {filePreview && (
                      <a
                        className="btn btn-primary mt-2"
                        href={filePreview}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Preview Uploaded File
                      </a>
                    )}
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="table-responsive">
                    <table className="table">
                      <div className="nk-tb-list">
                        <div className="nk-tb-item nk-tb-head">
                          <div className="nk-tb-col">
                            <span>Assignment Title</span>
                          </div>
                          <div className="nk-tb-col">
                            <span>Job Title</span>
                          </div>
                          <div className="nk-tb-col">
                            <span>Document</span>
                          </div>
                          <div className="nk-tb-col">
                            <span>Edit</span>
                          </div>
                        </div>

                        {currentItems.length == 0 && (
                          <div className="nk-tb-item">
                            <div className="nk-tb-col">
                              <span className="tb-product">
                                <span className="title">Good job! You are all caught up.</span>
                              </span>
                            </div>
                          </div>
                        )}
                        {currentItems.map((post, index) => (
                          <div className="nk-tb-item">
                            <div className="nk-tb-col">
                              <span className="tb-product">
                                <span className="title">
                                  {post?.assignment_title || "-"}
                                </span>
                              </span>
                            </div>

                            <div className="nk-tb-col">
                              <span className="tb-product">
                                <span className="title">
                                  {post?.job_opening?.job_title || "-"}
                                </span>
                              </span>
                            </div>

                            <div className="nk-tb-col">
                              <span className="tb-sub">
                                {post?.assignment_document ? (
                                  <a
                                    className="btn btn-primary"
                                    id="button-button-assignment_doc"
                                    href={post?.assignment_document}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <em
                                      className="icon ni ni-file-pdf"
                                      style={{ color: "white" }}
                                    ></em>
                                  </a>
                                ) : (
                                  "-"
                                )}
                              </span>
                            </div>

                            {privileges.includes("job_assignment_update") ? (
                              <div className="nk-tb-col">
                                <NavLink
                                  to={"/editjobassignment"}
                                  state={{ editpost: post }}
                                  className="btn btn-sm btn-primary"
                                  id="button-button-editassignment"
                                >
                                  <em className="icon ni ni-edit"></em>
                                </NavLink>
                              </div>
                            ) : (
                              " "
                            )}
                          </div>
                        ))}
                      </div>
                    </table>
                  </div>
                </div>

                <div className="card-inner">
                  <div className="nk-block-between-md g-3">
                    <div className="g">
                      <ReactPaginate
                        key={pageCount}
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={(data) => handlePageClick(data)}
                        containerClassName={
                          "pagination justify-content-center justify-content-md-start"
                        }
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                    <div className="g">
                      <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                        <div>
                          Total entries:
                          <span className="fw-bold">
                            {assignmentCheck === null ? 0 : assignmentCheck?.length}
                          </span>
                        </div>
                        <div>No of entries per page:</div>
                        <div>
                          <select
                            className="form-select js-select2"
                            data-search="on"
                            data-dropdown="xs center"
                            value={itemsPerPage}
                            onChange={handleEntries}
                            id="select-form-enteries"
                          >
                            <option value="5">5</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-light me-3"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    {submitLoading ? (
                      <button className="btn text-dark" disabled>
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-createscheduleinterview"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AddJobAssignment;
