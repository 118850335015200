import React, { useState, useEffect, useContext } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";
import { ViewResumeContext } from "../../Context/ViewResumeContext";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import moment from "moment";

const SMECandidateDrafted = () => {
  const { isAccess } = useContext(RefreshTokenContext);
  const [searchResume, setSearchResume] = useState("");
  console.log(searchResume);
  const [sortingDate, setSortingDate] = useState("");
  const navigate = useNavigate();
  const privileges = secureLocalStorage.getItem("privileges");
  const location = useLocation();
  console.log(location.state)

  let access = secureLocalStorage.getItem("access");

  const {
    getSMECandidateResume,
    getRCCandidateResume,
    getAllCandidateResume,
    rawDraftedCandidateData,
    getDraftedResumes,
    setDraftedEntries,
    draftedPageCount,
    draftedCount,
    isDraftedCandidateData,
    draftedDataLoading,
    handleDraftedClick,
    draftedEntries,
  } = useContext(ViewResumeContext);

  console.log(rawDraftedCandidateData)
  console.log("isDraftedCandidateData: ", isDraftedCandidateData)

  const handleEntries = (event) => {
    setDraftedEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchResume(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getDraftedResumes(1, location.state?.reqID, searchResume, sortingDate, access, location.state.shortlisting_type === 'SME' ? 'sme_drafted' : 'rc_drafted');
    } else {
      getDraftedResumes(1, location.state?.reqID, "", sortingDate, access, location.state.shortlisting_type === 'SME' ? 'sme_drafted' : 'rc_drafted');
      setSearchResume("");
    }
  };

  const handleSorting = (event) => {
    setSortingDate(event);
    getDraftedResumes(
      1,
      location.state.editpost.requisition.id,
      searchResume,
      event,
      access,
      location.state.shortlisting_type === 'SME' ? 'sme_drafted' : 'rc_drafted'
    );
  };

  const formatName = (name) => {
    if (!name) {
      return "-";
    } else {
      const parts = name.split(" ");
      console.log(parts);
      const firstName = parts[0]
        ? parts[0].charAt(0).toUpperCase() + parts[0].slice(1).toLowerCase()
        : "";
      const lastName = parts[1]
        ? parts[1].charAt(0).toUpperCase() + parts[1].slice(1).toLowerCase()
        : "";
      return `${firstName} ${lastName}`;
    }
  };

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      getDraftedResumes(1, location.state?.reqID, searchResume, "", access, location.state.shortlisting_type === 'SME' ? 'sme_drafted' : 'rc_drafted');
    } else {
      let access = secureLocalStorage.getItem("access");
      getDraftedResumes(1, location.state?.reqID, searchResume, "", access, location.state.shortlisting_type === 'SME' ? 'sme_drafted' : 'rc_drafted');
    }
  }, [isAccess, draftedEntries]);

  // useEffect(() => {
  //   if (isAccess) {
  //     let access = secureLocalStorage.getItem("access");
  //     getAllCandidateResume(location.state?.reqID, access, "drafted", location?.state?.shortlisting_type);
  //   }
  // }, [isAccess, draftedEntries]);

  return (
    <>
      <div className="nk-block-between">
        <div className="nk-block-head-content d-flex">
          <h3 className="nk-block-title page-title">
            {location.state.status === "viewallshortlisted"
              ? "Shortlisted candidate for "
              : "Candidate applied for "}
            {location.state?.editpost?.job_opening?.job_title || "-"}
          </h3>
          {draftedDataLoading ? (
            <div class="spinner-border text-primary ms-2" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          ) : (
            ""
          )}
        </div>

        <div class="nk-block-head-content">
          <a
            id="back-button"
            className="btn btn-light d-none d-sm-inline-flex"
            onClick={() => navigate(-1)}
          >
            <em class="icon ni ni-arrow-left"></em>
            <span>Back</span>
          </a>
          <a
            id="back-button"
            className="btn btn-icon btn-light d-inline-flex d-sm-none"
            onClick={() => navigate(-1)}
          >
            <em class="icon ni ni-arrow-left"></em>
          </a>
        </div>
      </div>
      <div className="nk-block">
        <div className="card mb-3">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchResume}
                      className="form-control"
                      placeholder="Search using Candidate Name and Email"
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        Clear Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Candidate Name</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Email-ID</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Mobile no.</span>
                      </div>

                      {/* {privileges.includes("sme_candidate_resume_update") ? ( */}
                      <div className="nk-tb-col">
                        <span>Resume</span>
                      </div>
                      {/* ) : (
                  " "
                )} */}
                      <div className="nk-tb-col">
                        <span className="d-flex align-center">
                          Drafted by RC on
                          <div class="card-tools me-n1 ms-1">
                            <ul class="btn-toolbar gx-1">
                              <li>
                                <div class="dropdown">
                                  <a
                                    href="javascript:void(0)"
                                    class="btn btn-trigger btn-icon dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                  >
                                    <em class="icon ni ni-filter-alt"></em>
                                  </a>
                                  <div class="dropdown-menu dropdown-menu-sm">
                                    <div class="dropdown-head">
                                      <a
                                        href="javascript:void(0)"
                                        class="sub-title dropdown-title"
                                        onClick={() => handleSorting("latest")}
                                      >
                                        Newest First
                                      </a>
                                    </div>
                                    <div class="dropdown-head">
                                      <a
                                        href="javascript:void(0)"
                                        class="sub-title dropdown-title"
                                        onClick={() => handleSorting("oldest")}
                                      >
                                        Oldest First
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </span>
                      </div>
                    </div>
                    {rawDraftedCandidateData.length !== 0 ? (
                      rawDraftedCandidateData.map((data, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {formatName(data?.candidate_name)}
                              </span>
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                {data?.email || "-"}
                              </span>
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                {data?.mobile || "-"}
                              </span>
                            </span>
                          </div>

                          {/* {privileges.includes(
                      "sme_candidate_resume_update"
                    ) ? ( */}
                          <div className="nk-tb-col">
                            {location.state.shortlisting_type === 'SME' ?
                              <span className="tb-sub">
                                {data?.resume ? (
                                  <NavLink
                                    to="/viewsmecandidateresume"
                                    className="btn btn-primary"
                                    id="button-button-viewresume"
                                    href={data?.resume}
                                    onClick={() =>
                                      getSMECandidateResume(
                                        location.state.editpost.requisition.id,
                                        "draft",
                                        "sme_drafted"
                                      )
                                    }
                                    state={{
                                      reqID:
                                        location.state.editpost.requisition.id,
                                      candidateID: data.id,
                                      from: "drafted",
                                    }}
                                  >
                                    <em
                                      className="icon ni ni-file-docs"
                                      style={{ color: "white" }}
                                    ></em>
                                  </NavLink>
                                ) : (
                                  "-"
                                )}
                              </span>
                              :
                              <span className="tb-sub">
                                {data?.resume ? (
                                  <NavLink
                                    to="/viewrccandidateresume"
                                    className="btn btn-primary"
                                    id="button-button-viewresume"
                                    href={data?.resume}
                                    onClick={() =>
                                      getRCCandidateResume(
                                        location.state.editpost.requisition.id,
                                        access,
                                        "draft",
                                        "rc_drafted"
                                      )
                                    }
                                    state={{
                                      reqID:
                                        location.state.editpost.requisition.id,
                                      candidateID: data.id,
                                      from: "drafted",
                                    }}
                                  >
                                    <em
                                      className="icon ni ni-file-docs"
                                      style={{ color: "white" }}
                                    ></em>
                                  </NavLink>
                                ) : (
                                  "-"
                                )}
                              </span>
                            }
                          </div>
                          {/* ) : (
                      " "
                    )} */}
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                {moment(data?.updated_at).format(
                                  "Do MMMM YYYY"
                                ) || "-"}
                              </span>
                            </span>
                          </div>
                        </div>
                      ))
                    ) : isDraftedCandidateData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">
                              Good job! You are all caught up.
                            </span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={draftedPageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={draftedPageCount}
                    marginPagesDisplayed={2}
                    onPageChange={(data) =>
                      handleDraftedClick(
                        data,
                        location.state?.reqID,
                        searchResume,
                        "",
                        access,
                        location.state.shortlisting_type === 'SME' ? 'sme_drafted' : 'rc_drafted'
                      )
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"paginate_button page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"paginate_button page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {draftedCount === null ? 0 : draftedCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={draftedEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SMECandidateDrafted;
