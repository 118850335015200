import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";
import { useLocation, useNavigate } from "react-router-dom";
import { GenericDropdownContext } from "../../Context/GenericDropdownContext";
import { NotificationsContext } from "../../Context/NotificationsContextProvider";

const WhatsAppNotificationEdit = () => {
    const userID = secureLocalStorage.getItem("userID");

    const { getWhatsAppNotificationData } = useContext(NotificationsContext);
    const { usersDropdownData, isGenricParamsDropdownDataLoading, roleDropdownData } = useContext(GenericDropdownContext);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control,
    } = useForm();

    let location = useLocation();
    let navigate = useNavigate();

    const [submitMessage, setSubmitMessage] = useState(null);
    const [submitLoading, setSubmitLoading] = useState(false);

    const [showFullMessage, setShowFullMessage] = useState(false);

    const toggleMessage = () => {
        setShowFullMessage(!showFullMessage);
    };

    const message =
        location?.state?.editpost?.template_message || "";
    const shortMessage =
        message.length > 325
            ? `${message.substring(0, 325)} . . .`
            : message;

    const onSubmit = (data) => {
        console.log(data)
        var user = JSON.stringify({
            user_id: data.user_id?.map((info) => info.value),
            role_id: data.role_id?.map((info) => info.value),
            specific_user: data.user_id && data.user_id.length !== 0 ? true : false,
            updated_by_id: userID,
        });

        const url =
            VARIABLES.url + `/api/whatsapp-notification-config/${location.state.editpost.id}/`;

        var config = {
            method: "patch",
            url: url,
            headers: {
                "Content-Type": "application/json",
            },
            data: user,
        };

        setSubmitLoading(true);
        axios(config)
            .then((response) => {
                setSubmitLoading(false);
                console.log(JSON.stringify(response.data));
                getWhatsAppNotificationData();
                setSubmitMessage({
                    type: "success",
                    icon: "check",
                    message: "Notification updated successfully",
                });
                setTimeout(() => {
                    navigate(-1);
                }, 2000);
                reset();
            })
            .catch((error) => {
                setSubmitLoading(false);
                console.log(error);
                setSubmitMessage({
                    type: "danger",
                    icon: "cross",
                    message: "Failed to update notification",
                });
            });
    };

    useEffect(() => {
        console.log(location.state.editpost);
        const editedNotification = location.state.editpost;
        if (editedNotification) {
            var defaultValues = {};
            defaultValues.user_id = editedNotification.user_id;
            const getUsers = editedNotification?.user?.map((user) => ({
                value: user.id,
                label: `${user?.first_name || "-"} ${user?.last_name || "-"}`,
            }));
            defaultValues.user_id = getUsers;

            const getRoles = editedNotification?.role?.map((role) => ({
                value: role.id,
                label: role.role_name,
            }));
            defaultValues.role_id = getRoles;

            reset(defaultValues);

        }
    }, []);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setSubmitMessage(null);
        }, 5000);

        return () => clearTimeout(timeoutId);
    }, [submitMessage]);

    return (
        <>
            <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                    <div className="nk-block-head-content">
                        <h4 className="nk-block-title"> Edit WhatsApp Notification </h4>
                    </div>
                    <div class="nk-block-head-content">
                        <a
                            id="button-back"
                            className="btn btn-light d-none d-sm-inline-flex"
                            onClick={() => navigate(-1)}
                        >
                            <em class="icon ni ni-arrow-left"></em>
                            <span>Back</span>
                        </a>
                        <a
                            id="button-back"
                            className="btn btn-icon btn-light d-inline-flex d-sm-none"
                            onClick={() => navigate(-1)}
                        >
                            <em class="icon ni ni-arrow-left"></em>
                        </a>
                    </div>
                </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div class="card">
                    <div class="card-inner">
                        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                            <div className="row g-4">
                                <div className="col-lg-12">
                                    <h6>Stage : {location.state.editpost?.stage}</h6>
                                </div>

                                <div className="col-lg-12 d-flex" style={{ alignItems: "baseline" }}>
                                    <h6 style={{ width: "90px", textWrap: "nowrap" }}>Message :</h6>
                                    <p className="ms-1 fw-bold">
                                        <p
                                            className="m-0"
                                            dangerouslySetInnerHTML={{
                                                __html: showFullMessage
                                                    ? message
                                                    : shortMessage,
                                            }}
                                        />
                                        {message.length > 325 && (
                                            <button
                                                type="button"
                                                className="badge bg-info"
                                                onClick={toggleMessage}
                                            >
                                                {showFullMessage ? "show less" : "show more "}
                                            </button>
                                        )}
                                    </p>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label className="form-label">Users</label>
                                        <Controller
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    options={usersDropdownData?.map((info) => ({
                                                        value: info?.user.id,
                                                        label: `${info?.user.first_name} ${info?.user.last_name}`,
                                                    }))}
                                                    isClearable={true}
                                                    isLoading={isGenricParamsDropdownDataLoading}
                                                    id="select-forms-user_id"
                                                    isMulti
                                                />
                                            )}
                                            defaultValue=""
                                            name="user_id"
                                            control={control}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label className="form-label">Role</label>
                                        <Controller
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    options={roleDropdownData?.map((info) => ({
                                                        value: info.id,
                                                        label: info.role_name,
                                                    }))}
                                                    isClearable={true}
                                                    isLoading={isGenricParamsDropdownDataLoading}
                                                    id="select-forms-role_id"
                                                    isMulti
                                                />
                                            )}
                                            defaultValue=""
                                            name="role_id"
                                            control={control}
                                        />
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="form-group">
                                        <button
                                            type="button"
                                            className="btn btn-lg btn-light me-3"
                                            id="submit-button-backbutton"
                                            onClick={() => navigate(-1)}
                                        >
                                            Cancel
                                        </button>
                                        {submitLoading ? (
                                            <button
                                                id="button_loading"
                                                className="btn text-dark"
                                                disabled
                                            >
                                                Loading...
                                            </button>
                                        ) : (
                                            <button
                                                type="submit"
                                                className="btn btn-lg btn-primary"
                                                id="submit-button-editnotification"
                                            >
                                                Update
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div>
                {submitMessage && (
                    <div className="example-alerts position-fixed bottom-0 end-0 p-3">
                        <div className="gy-4">
                            <div className="example-alert">
                                <div
                                    className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                                >
                                    <em
                                        className={`icon ni ni-${submitMessage.icon}-circle`}
                                    ></em>
                                    <strong>{submitMessage.message}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default WhatsAppNotificationEdit;
